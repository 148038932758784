export const environment = {
  production: false,
  backendUrl: 'https://api-nms.pre.hispasat.es/v1/broadband/backend',
  jupiter: 'https://jupiter-nms.hispasat.es',
  googlemaps: 'AIzaSyDd5F3KWGVoBBDvmmeieaU8gRI1qAabrq8',
  googlemaps_axess: 'AIzaSyCc5C_mDLefkETcJoxSXBAg41GAPnUx2Ro',
  azure_logout:
    'https://b2ctesthispasat.b2clogin.com/b2ctestHispasat.onmicrosoft.com/B2C_1_Axess_Login/oauth2/v2.0/logout',
  sngsolb2c: 'https://b2ctestHispasat.b2clogin.com',
  msalConfig: {
    auth: {
      clientId: 'e3c0b5a7-1ef7-4ef2-9ac4-4bdc59ecbc28'
    }
  },
  apiConfig: {
    scopes: [' openid']
  },
  b2cPolicies: {
    names: {
      signUpSignIn: 'B2C_1_Axess_Login'
    },
    authorities: {
      signUpSignIn: {
        authority: 'https://b2ctestHispasat.b2clogin.com/b2ctestHispasat.onmicrosoft.com/B2C_1_Axess_Login'
      }
    },
    authorityDomain: 'b2ctestHispasat.b2clogin.com'
  }
};
