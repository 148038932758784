 <div  *ngIf="  showInput && isEditable" class="form-control" style="    position: relative;">




      <div   class="column-text">
        <input
        type="text"
        class="pba-input-search form-control  ps-1  edit-input"
        [(ngModel)]="data.name"
        (ngModelChange)="onInputChange($event)"
        />
      </div> 

      <div>

      <div
      *ngIf="  showInput && isEditable"
      class="button-clear-filters"
      (click)="onClickDelete()">
      X
      </div>


      <div
      *ngIf="  showInput && isEditable && !isEmpty"
      class="button-clear-filters2"
      (click)="onClickSave()">
      <i class="icon-check-lg icon-list-edit icon-map"></i>
      </div>

    </div>

</div> 
