<div class="justify-modal-center global-slot-share">
  <div class="modal-header bd-example-modal-lg">
    <h5>{{ 'COMPONENT.TABLE.FAVORITE.ADD' | translate }}</h5>

    <button
      type="button"
      class="btn-close"
      data-bs-dismiss="modal"
      aria-label="Close"
      (click)="bsModalRef.hide()"></button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12">
        <label class="col-form-label pba-input-label" for="usr"> {{ 'COMMONS.NAME' | translate }} </label>
        <input
          #favoriteInput
          [(ngModel)]="favoriteName"
          (ngModelChange)="onChangeText()"
          type="text"
          class="form-control pba-input-text" />
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <div style="display: flex">
      <button type="button" class="btn btn-outline" (click)="bsModalRef.hide()">
        {{ 'COMMONS.DECLINE' | translate }}
      </button>
      <button
        [disabled]="!enabledSave"
        (click)="onConfirm()"
        class="btn btn-primary pba-btn-lg"
        style="margin-left: 1rem"
        data-bs-target="#exampleModal"
        data-bs-toggle="modal">
        {{ 'COMMONS.ACCEPT' | translate }}
      </button>
    </div>
  </div>
</div>
