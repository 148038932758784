export class EnumUtils {
  // static getValueByKeyForStringEnum<Ts>(value: string, e: Ts): string {
  //   return Object.entries(e).find(([key]) => key === value)?.[1] as string;
  // }

  static getValueByKeyForStringEnumGen<T extends { [index: string]: string }>(
    enumValue: string,
    myEnum: T
  ): keyof T | null {
    //const keys = Object.keys(myEnum).filter(x => myEnum[x] == enumValue);
    if (myEnum[enumValue]) {
      return myEnum[enumValue];
    }

    return null;

    //return keys.length > 0 ? keys[0] : null;
  }

  static getValueByKeyForStringEnum<T extends { [index: string]: string }>(enumValue: string, myEnum: T): string {
    return EnumUtils.getValueByKeyForStringEnumGen(enumValue, myEnum) as string;
  }
}
