import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { createComboSearchDate } from '@shared/components/table/utils/search-modifiers.util';
import { DropdownItemInterface } from '@shared/components/dropdown/interfaces/dropdown-item.interface';
import { TranslateService } from '@ngx-translate/core';
import { DropdownItem } from '@shared/components/dropdown/interfaces/dropdown-item.model';
import { DropDownSelected } from '@shared/components/dropdown/interfaces/dropdown-selected.interface';
import * as moment from 'moment/moment';
import { distinctUntilChanged, Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { BsDatepickerConfig, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-date-picker-search',
  templateUrl: './date-picker-search.component.html',
  styleUrls: ['./date-picker-search.component.scss']
})
export class DatePickerSearchComponent implements OnInit, OnDestroy {
  @ViewChild('criteriaSearch', { static: false }) private criteriaSearchRef?: ElementRef;
  @ViewChild('dropdownRef', { static: false }) private dropdownRef!: ElementRef;
  @ViewChildren('dropdownItemRef', { read: ElementRef }) private dropdownItemRef!: QueryList<ElementRef>;

  @Input() infinite = false;
  @Input() optional?: DropdownItemInterface;
  @Input() modulo = '';
  @Input() minMode? = 'day';

  @Output() infiniteScroll = new EventEmitter<boolean>();
  @Output() selectDropDown = new EventEmitter<DropDownSelected>();
  public criteriaDropDown = createComboSearchDate();
  public criteriaSelIndex = 0;
  public criteriaSearchValue = '';
  public datePickerBsConfig: Partial<BsDatepickerConfig> = {
    adaptivePosition: true,
    dateInputFormat: 'DD-MM-YYYY',
    showWeekNumbers: false
  };
  public selectedDate!: Date | undefined;
  public dateInvalid = false;
  public minDate!: Date;
  public maxDate = new Date();
  public updateInputDelay$ = new Subject<Event>();
  public showButtonClearFilters = false;

  private focusIndex = 0;
  private focusDownIndex = 1;
  private cellSize = 45;
  private cellGroupSize = this.cellSize * 4;

  private clearfilters$ = new Subscription();
  private filterActive = false;

  constructor(
    private readonly translate: TranslateService,
    private localeService: BsLocaleService,
    private store: Store
  ) {
    this.localeService.use('es');
    this.translate.store.onLangChange.subscribe(({ lang }) => {
      this.localeService.use(lang);
    });

    this.clearfilters$ = this.store
      .select((state: any) => state.shared.table[this.modulo])
      .subscribe(modulo => {
        if (modulo && modulo.length <= 0) {
          this.clearfilters(false);
        }
      });
  }

  ngOnInit() {
    this.updateInputDelay$.pipe(debounceTime(800), distinctUntilChanged()).subscribe(event => {
      const newDateValue = (event.target as HTMLInputElement).value;
      if (newDateValue.length) {
        const momentDate = moment(newDateValue, 'DD-MM-YYYY HH:mm');
        this.dateInvalid = !momentDate.isValid();
        if (momentDate.isValid()) {
          this.selectedDate = momentDate.toDate();
          this.emitSearchEvent();
        }
      } else {
        this.selectedDate = undefined;
        this.emitSearchEvent();
      }
    });

    if (this.minMode && this.minMode === 'month') {
      this.datePickerBsConfig.minMode = 'month';
    }
  }

  ngOnDestroy() {
    if (this.clearfilters$) this.clearfilters$.unsubscribe();
  }

  public toggleDropDown() {
    if (!this.infinite) {
      setTimeout(() => {
        if (this.criteriaSearchRef) {
          this.criteriaSearchRef.nativeElement.focus();
        }
        this.criteriaSearchValue = '';
        this.criteriaDropDown.dataItems.forEach(node => {
          node.focus = false;
          node.show = true;
        });
        this.dropdownItemRef['_results'].forEach((ref: any) => (ref.nativeElement.style.display = 'flex'));
        let index = this.dropdownItemRef['_results'].findIndex(
          (element: any) => +element.nativeElement.id === this.criteriaDropDown.dataItems[this.criteriaSelIndex || 0].id
        );
        if (index === -1) {
          index = 0;
        }
        this.focusIndex = index;
        this.criteriaDropDown.dataItems[this.focusIndex].focus = true;
        this.focusDownIndex = 1;
        if (this.focusIndex > 4) {
          this.focusDownIndex = this.focusIndex - 3;
        }
        this.changeKeyDownArrowDownScroll();
      }, 0);
    }
  }

  public onScroll() {
    if (this.infinite) {
      const scrollTop = this.dropdownRef.nativeElement.scrollTop;
      const clientHeight = this.dropdownRef.nativeElement.clientHeight;
      const scrollHeight = this.dropdownRef.nativeElement.scrollHeight;
      if (scrollTop + clientHeight + 1 >= scrollHeight) {
        this.infiniteScroll.emit();
      }
    }
  }

  public onBlurInputSearch() {
    this.dropdownRef.nativeElement.focus();
  }

  public onChangeTextSearch(searchText: string) {
    this.filterStatusNode(searchText);
    this.initFocus();
    this.dropdownRef.nativeElement.scrollTop = 0;
  }

  public onKeyDownArrowDown() {
    if (this.focusIndex < this.getListWithNodeShow().length - 1) {
      this.changeKeyDownArrowDownFocus();
      this.changeKeyDownArrowDownScroll();
    }
  }

  public onKeyDownArrowUp() {
    if (this.focusIndex !== 0) {
      this.changeKeyDownArrowUpFocus();
      this.changeKeyDownArrowUpScroll();
    }
  }

  public onKeyUpSelected(event: any) {
    event.preventDefault();
    // Simulamos click debido a que bootstrap al hacer filtrado en el input y no se entera del nuevo dom.
    const elements: Array<ElementRef> = this.dropdownItemRef['_results'].filter(
      (ref: any) => ref.nativeElement.style.display !== 'none'
    );
    elements[this.focusIndex].nativeElement.click();
  }

  public onClickCriteria(itemSelected: DropdownItemInterface, event?: any, index?: number) {
    this.criteriaSelIndex = index || 0;
    itemSelected.selected = true;
    this.emitSearchEvent();
  }

  public onOpenCalendar(container: any) {
    container.daySelectHandler = (event: any): void => {
      container._store.dispatch(container._actions.select(event.date));
    };
    container.monthSelectHandler = (event: any): void => {
      container._store.dispatch(container._actions.select(event.date));
    };
    if (this.datePickerBsConfig) {
      container.setViewMode('day');

      const minNumberDays = (this.datePickerBsConfig as any).minNumberDays as number;
      if (minNumberDays) {
        this.minDate = moment(new Date()).subtract(minNumberDays, 'days').toDate();
      }
    }
  }

  public emitSearchEvent() {
    const item: DropdownItem = this.criteriaDropDown.dataItems[this.criteriaSelIndex || 0] as DropdownItem;
    let selDate = '';
    if (this.selectedDate) {
      selDate = moment(this.selectedDate).utc(true).set({ hours: 0, minutes: 0, second: 0 }).format();
    }
    const dataEmit = {
      index: this.criteriaSelIndex || 0,
      item: item,
      selected: item.selected,
      searchText: selDate
    };
    this.selectDropDown.emit(dataEmit);

    if (!this.filterActive) {
      this.filterActive = true;
    }
  }

  public trackById = (i: number, e: any) => e.id;

  private filterStatusNode(searchText: string) {
    this.criteriaDropDown.dataItems.forEach(node => {
      node.show = !(!node.name || !this.translate.instant(node.name).toLowerCase().includes(searchText.toLowerCase()));
      if (node.selected) {
        node.show = true;
      }
    });
  }

  private initFocus() {
    if (this.criteriaDropDown.dataItems && this.getListWithNodeShow().length) {
      this.focusIndex = 0;
      this.focusDownIndex = 1;
      this.criteriaDropDown.dataItems.forEach(op => (op.focus = false));
      this.getListWithNodeShow()[this.focusIndex].focus = true;
    }
  }

  private getListWithNodeShow() {
    return this.criteriaDropDown.dataItems.filter(node => node.show === undefined || node.show);
  }

  private changeKeyDownArrowDownFocus() {
    this.getListWithNodeShow()[this.focusIndex].focus = false;
    this.focusIndex++;
    this.getListWithNodeShow()[this.focusIndex].focus = true;
  }

  private changeKeyDownArrowDownScroll() {
    const topPos = this.getTopPosDropDownShow();
    const pos = this.focusDownIndex * this.cellSize + 4 * this.cellSize;
    if (topPos >= pos) {
      this.dropdownRef.nativeElement.scrollTop = topPos - this.cellGroupSize;
      this.focusDownIndex++;
    }
  }

  private changeKeyDownArrowUpFocus() {
    this.getListWithNodeShow()[this.focusIndex].focus = false;
    this.focusIndex--;
    this.getListWithNodeShow()[this.focusIndex].focus = true;
  }

  private changeKeyDownArrowUpScroll() {
    const topPos = this.getTopPosDropDownShow();
    if (this.focusDownIndex * this.cellSize > topPos) {
      this.dropdownRef.nativeElement.scrollTop = topPos - this.cellSize;
      this.focusDownIndex--;
    }
  }

  private getTopPosDropDownShow() {
    const elements = this.dropdownItemRef['_results'].filter((ref: any) => ref.nativeElement.style.display !== 'none');
    const topPos = elements[this.focusIndex] ? elements[this.focusIndex].nativeElement.offsetTop : 0;
    return topPos;
  }

  onbsDatepickerValueChange(event: any) {
    if (event) this.showButtonClearFilters = true;
  }

  clearfilters(donw: boolean) {
    if (donw) {
      // this.store.dispatch(fromActions.tableActiveFiltersDOWN());
    }

    this.showButtonClearFilters = false;
    this.selectedDate = undefined;
    this.filterActive = false;

    this.selectDropDown.emit({
      index: 0,
      item: new DropdownItem(0, ''),
      selected: false,
      searchText: ''
    });
  }
}
