import { Subject } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  templateUrl: './modal-confirm.component.html',
  styleUrls: ['./modal-confirm.component.scss']
})
export class ModalConfirmComponent implements OnInit, OnDestroy {
  @Input() textToShow = this.translate.instant('COMMONS.CONTINUE');
  @Input() params = '';
  @Input() extraInfoParams = '';
  @Input() showCancel = true;
  @Input() extraInfo = false;
  @Input() showButtons = true;
  public onCloseSubject!: Subject<boolean>;

  constructor(private bsModalRef: BsModalRef, private readonly translate: TranslateService) {}
  public ngOnInit(): void {
    this.onCloseSubject = new Subject();
  }

  public onConfirm(): void {
    this.onCloseSubject.next(true);
    this.bsModalRef.hide();
  }

  public onCancel(): void {
    this.onCloseSubject.next(false);
    this.bsModalRef.hide();
  }

  ngOnDestroy() {
    this.onCloseSubject.next(false);
    this.bsModalRef.hide();
  }
  trackByIndex = (i: number) => i;
}
