<div   class="pba-table font-size">
  <div *ngIf="isDotOptions" class="teleport-destination">
    <ng-container *ngFor="let teleportedTemplate of teleportedTemplates; trackBy: trackByIndex">
      <ng-container [cdkPortalHost]="teleportedTemplate"></ng-container>
    </ng-container>
  </div>

  <div class="comp-table" [ngClass]="{ 'no-scroll': !config.showScroll }">
    <div *ngIf="showEmptyResult && showEmpty" class="empty-result" [ngClass]="{ 'empty-result-top': !isSearchColumn }">
      No se han encontrado resultados
    </div>

    <div class="table-size ">
      <div #headerWrap class="head-container" [ngClass]="{ 'head-container__over': overflowHeader }">
        <table class="table-size font-size">
          <thead class="table-head">
            <tr class="row-head">
              <th
                #thItemRef
                *ngFor="let paramsConf of config.tableConfigs; let ih = index"
                class="p-0 column-head col{{ ih }} "
                [ngClass]="{
                  actions: paramsConf.type === typeEnum.ACTIONS && paramsConf.width === undefined,
                  state: paramsConf.type === typeEnum.STATE,
                  'column-head-sticky': config.numColumnSticky > ih
                }"
                [ngStyle]="{
                  'min-width': paramsConf.minWidth,
                  width: paramsConf.width,
                  'z-index': config.tableConfigs.length - ih
                }">
                <div
                  [ngClass]="{
                    'table-head__body': paramsConf.type === typeEnum.COMBO_MULTIPLE
                  }">
                  <div
                    appDoubleClick
                    (singleClick)="onSingleClickColumn(paramsConf, ih)"
                    (doubleClick)="onDoubleClickColumn(paramsConf, ih)"
                    class="d-column-title d-flex justify-content-between"
                    [ngClass]="this.getClassThHead(paramsConf)"
                    *ngIf="paramsConf.des !== 'TERMINAL.TABLE.LIST.STATUS'">
                    <div class="pba-check" *ngIf="paramsConf.type === typeEnum.MASS_CHECK; else showNameHeader">
                      <input
                        class="pba-check-input"
                        type="checkbox"
                        [checked]="checkAll"
                        id="{{ 'customCheckHead' + ih }}"
                        (click)="onClickMassCheckAll()" />
                      <label class="pba-check-text" for="{{ 'customCheckHead' + ih }}"></label>
                      <label for="{{ 'customCheckHead' + ih }}" class="pba-check-mark"></label>
                    </div>

                    <ng-template #showNameHeader>
                      <span class="ps-2 column-title align-self-end mb-2">{{ paramsConf.des | translate }}</span>
                      <i
                        class="icon-arrow icon-right d-flex align-self-center"
                        [ngClass]="iconOrderClasses(paramsConf)"></i>
                    </ng-template>
                  </div>

                  <div
                    *ngIf="paramsConf.type !== typeEnum.ACTIONS && paramsConf.type !== typeEnum.MASS_CHECK && paramsConf.type !== typeEnum.STATUS_FIELD"
                    appResize
                    class="resize"></div>

                  <div
                    (click)="onSingleClickColumn(paramsConf, ih)"
                    class="d-column-title d-flex justify-content-between"
                    [ngClass]="{
                      selected: paramsConf.selected,
                      'cursor-pointer': paramsConf.sort,
                      'cursor-context-menu': !paramsConf.sort
                    }"
                    *ngIf="paramsConf.des === 'TERMINAL.TABLE.LIST.STATUS'"
                    data-bs-toggle="tooltip"
                    title="{{ 'ICONS.TOOLTIPS_TERMINAL_STATUS' | translate }}">
                    <span class="ps-2 column-title align-self-end mb-2">{{ paramsConf.des | translate }}</span>

                    <i
                      class="icon-arrow icon-right d-flex align-self-center"
                      [ngClass]="iconOrderClasses(paramsConf)"></i>
                  </div>
                  <ng-container *ngIf="config.showSecondRowHeaderFilterOptions">
                    <ng-container
                      *ngIf="
                        showSecondRow(paramsConf) ||
                          paramsConf.type === typeEnum.DATE_FILTER ||
                          paramsConf.type === typeEnum.DATE_MONTH_FILTER;
                        else withoutSearch
                      ">
                      <div
                        class="m-1"
                        *ngIf="
                          paramsConf.type !== typeEnum.DATE_FILTER && paramsConf.type !== typeEnum.DATE_MONTH_FILTER
                        ">
                        <input
                          type="text"
                          class="pba-input-search form-control ps-1"
                          [(ngModel)]="searchParams[paramsConf.name]"
                          placeholder="{{ 'COMMONS.SEARCH' | translate }}"
                          #keyupHead
                          (input)="onKeyHeadSearch(keyupHead.value, ih)" />
                      </div>
                      <app-date-picker-search
                        *ngIf="paramsConf.type === typeEnum.DATE_FILTER"
                        (selectDropDown)="onDropDownSearch(ih, $event)"></app-date-picker-search>

                      <app-date-picker-search
                        *ngIf="paramsConf.type === typeEnum.DATE_MONTH_FILTER"
                        [minMode]="'month'"
                        (selectDropDown)="onDropDownSearch(ih, $event)"></app-date-picker-search>
                    </ng-container>
                    <ng-template #withoutSearch>
                      <div
                        style="width: 200px"
                        class="px-1"
                        *ngIf="paramsConf.type?.startsWith(typeEnum.COMBO); else emptyCombo">
                        <app-table-filter-select-multi
                          *ngIf="paramsConf.type === typeEnum.COMBO_MULTIPLE"
                          class="pba-dropdown-table"
                          [optional]="{
                            id: -1,
                            name: 'COMMONS.SELECT_ALL',
                            show: true,
                            focus: false,
                            selected: false
                          }"
                          [modulo]="config.module"
                          [options]="paramsConf.data"
                          [optionsFormMultiple]="optionsFormMultiple"
                          (clickPbaDropdownMultiple)="onDropdownMultiple(ih, $event)"
                          (filterEvent)="onfilterEvent(ih, $event)">
                        </app-table-filter-select-multi>

                        <app-table-filter-select-boolean
                          *ngIf="paramsConf.type === typeEnum.COMBO_BOOLEAN && paramsConf.enableSearchBoolean"
                          class="pba-dropdown-table"
                          [selectOptionBoolean]="selectOptionBoolean"
                          [options]="paramsConf.data"
                          (clickPbaDropdown)="onDropDownBooleanSearch(ih, $event)"
                          (filterEvent)="onfilterEvent(ih, $event)">
                          </app-table-filter-select-boolean
                        >


                        <div *ngIf="paramsConf.type === typeEnum.COMBO_BOOLEAN && !paramsConf.enableSearchBoolean" class="wo-search">
                          <div
                            class="d-flex justify-content-center align-items-center"
                            >
                         
                          </div>
                        </div>

                        <app-dropdown
                          *ngIf="
                            paramsConf.type !== typeEnum.COMBO_MULTIPLE && paramsConf.type !== typeEnum.COMBO_BOOLEAN
                          "
                          [optional]="{
                            id: -1,
                            name: 'COMMONS.SELECT',
                            show: true,
                            focus: false,
                            selected: false
                          }"
                          [options]="paramsConf.data"
                          class="pba-dropdown-table"
                          (clickPbaDropdown)="onDropDownSearch(ih, $event)"
                          [disabled]="paramsConf.disabled">
                        </app-dropdown>
                      </div>
                      <ng-template #emptyCombo>
                        <div class="wo-search">
                          <div
                            class="d-flex justify-content-center align-items-center"
                            *ngIf="paramsConf.type === typeEnum.MASS_CHECK; else emptyMassCheck">
                            <div class="position-relative">
                              <p class="cursor-pointer column-head__3dot m-0 p-0" (click)="onClick3Dots()">...</p>
                              <app-dropdown-menu
                                [options]="paramsConf.data"
                                (clickPbaDropdownMenu)="onDropDownMenu(ih, $event)"
                                [eventsDropDownMenu]="eventShowMenuSubject.asObservable()"></app-dropdown-menu>
                            </div>
                          </div>
                        </div>
                      </ng-template>
                      <ng-template #emptyMassCheck>
                        <div
                          class="px-1 table-dropdown-input"
                          *ngIf="paramsConf.type === typeEnum.INPUT; else emptyHeader">
                          <app-table-filter-input
                            class="pba-dropdown-table"
                            [modulo]="config.module"
                            [options]="optionsSearchModifiers[paramsConf.name].dropDown.dataItems"
                            [selectedItem]="optionsSearchModifiers[paramsConf.name].dropDown.selectedItem"
                            (clickPbaDropdown)="onDropDownSearch(ih, $event)"
                            (filterEvent)="onfilterEvent(ih, $event)">
                          </app-table-filter-input>
                        </div>
                      </ng-template>
                      <ng-template #emptyHeader>
                        <div
                          [ngClass]="{
                            'wo-search': isSearchColumn,
                            'mb-3': !isSearchColumn
                          }">
                          <div style="display: flex; justify-content: flex-start; margin-left: 8px">
                            <app-table-share-search
                              *ngIf="paramsConf.type === typeEnum.ACTIONS"
                              [tableName]="config.module"
                              [searchParams$]="searchParams$.asObservable()"
                              [hasFilter]="showButtonClearFilters && config.module !== ''">
                            </app-table-share-search>

                            <app-favorites
                              *ngIf="paramsConf.type === typeEnum.ACTIONS"
                              [tableName]="config.module"
                              [searchParams$]="searchParams$.asObservable()"
                              [hasFilter]="showButtonClearFilters && config.module !== ''"
                              (querySelect)="onSelectQuery($event, true)">
                            </app-favorites>

                            <i
                              class="btn btn-sm icon-times-outline-blue-sm button-clear-filters"
                              *ngIf="paramsConf.type === typeEnum.ACTIONS && showButtonClearFilters"
                              (click)="onClearFilters()"
                              title="{{ 'COMPONENT.TABLE.CLEARFILTERS' | translate }}">
                            </i>
                          </div>
                        </div>
                      </ng-template>
                    </ng-template>
                  </ng-container>
                </div>
              </th>
            </tr>
          </thead>
        </table>
      </div>

      <div
      appClickOutside
        (scrollOutside)="onScrollOutSideHidden()"
        (scroll)="onScroll($event)"
        class="table-body-container"
        [ngClass]="{ 'table-body-container__over': overflowHeader, 'table-body-container-person': minitable }">
        <table class="table-size font-size">
          <thead style="visibility: collapse" class="table-head">
            <tr class="row-head">
              <th
                #thItemRef
                *ngFor="let paramsConf of config.tableConfigs; let ih = index"
                class="p-0 column-head col{{ ih }} "
                [ngClass]="{
                  actions: paramsConf.type === typeEnum.ACTIONS && paramsConf.width === undefined,
                  state: paramsConf.type === typeEnum.STATE,
                  'column-head-sticky': config.numColumnSticky > ih
                }"
                [ngStyle]="{
                  'min-width': paramsConf.minWidth,
                  width: paramsConf.width,
                  'z-index': config.tableConfigs.length - ih
                }">
                <i
                  class="btn btn-sm icon-times-outline-blue-sm button-clear-filters"
                  *ngIf="paramsConf.type === typeEnum.ACTIONS && showButtonClearFilters"
                  (click)="onClearFilters()"
                  title="{{ 'COMPONENT.TABLE.CLEARFILTERS' | translate }}">
                </i>

                <div
                  [ngClass]="{
                    'table-head__body': paramsConf.type === typeEnum.COMBO_MULTIPLE
                  }">
                  <div
                    (click)="onSingleClickColumn(paramsConf, ih)"
                    class="d-column-title d-flex justify-content-between"
                    [ngClass]="this.getClassThHead(paramsConf)"
                    *ngIf="paramsConf.des !== 'TERMINAL.TABLE.LIST.STATUS'">
                    <div class="pba-check" *ngIf="paramsConf.type === typeEnum.MASS_CHECK; else showNameHeader">
                      <input
                        class="pba-check-input"
                        type="checkbox"
                        [checked]="checkAll"
                        id="{{ 'customCheckHead' + ih }}"
                        (click)="onClickMassCheckAll()" />
                      <label class="pba-check-text" for="{{ 'customCheckHead' + ih }}"></label>
                      <label for="{{ 'customCheckHead' + ih }}" class="pba-check-mark"></label>
                    </div>

                    <ng-template #showNameHeader>
                      <span class="ps-2 column-title align-self-end mb-2">{{ paramsConf.des | translate }}</span>
                      <i
                        class="icon-arrow icon-right d-flex align-self-center"
                        [ngClass]="iconOrderClasses(paramsConf)"></i>
                    </ng-template>
                  </div>
                  <div
                    (click)="onSingleClickColumn(paramsConf, ih)"
                    class="d-column-title d-flex justify-content-between"
                    [ngClass]="{
                      selected: paramsConf.selected,
                      'cursor-pointer': paramsConf.sort,
                      'cursor-context-menu': !paramsConf.sort
                    }"
                    *ngIf="paramsConf.des === 'TERMINAL.TABLE.LIST.STATUS'"
                    data-bs-toggle="tooltip"
                    title="{{ 'ICONS.TOOLTIPS_TERMINAL_STATUS' | translate }}">
                    <span class="ps-2 column-title align-self-end mb-2">{{ paramsConf.des | translate }}</span>
                    <i
                      class="icon-arrow icon-right d-flex align-self-center"
                      [ngClass]="iconOrderClasses(paramsConf)"></i>
                  </div>
                  <ng-container *ngIf="config.showSecondRowHeaderFilterOptions">
                    <div *ngIf="showSecondRow(paramsConf); else withoutSearch" class="m-1">
                      <input
                        type="text"
                        class="pba-input-search form-control ps-1"
                        [(ngModel)]="searchParams[paramsConf.name]"
                        placeholder="{{ 'COMMONS.SEARCH' | translate }}"
                        #keyupHead
                        (input)="onKeyHeadSearch(keyupHead.value, ih)" />
                    </div>
                    <ng-template #withoutSearch>
                      <div class="px-1" *ngIf="paramsConf.type?.startsWith(typeEnum.COMBO); else emptyCombo">
                        <app-dropdown
                          *ngIf="
                            paramsConf.type !== typeEnum.COMBO_MULTIPLE && paramsConf.type !== typeEnum.COMBO_BOOLEAN
                          "
                          [optional]="{
                            id: -1,
                            name: 'COMMONS.SELECT',
                            show: true,
                            focus: false,
                            selected: false
                          }"
                          [options]="paramsConf.data"
                          class="pba-dropdown-table"
                          (clickPbaDropdown)="onDropDownSearch(ih, $event)"
                          [disabled]="paramsConf.disabled">
                        </app-dropdown>
                      </div>
                      <ng-template #emptyCombo>
                        <div
                          class="wo-search d-flex justify-content-center align-items-center"
                          *ngIf="paramsConf.type === typeEnum.MASS_CHECK; else emptyMassCheck">
                          <div class="position-relative">
                            <p class="cursor-pointer column-head__3dot m-0 p-0" (click)="onClick3Dots()">...</p>
                            <app-dropdown-menu
                              [options]="paramsConf.data"
                              (clickPbaDropdownMenu)="onDropDownMenu(ih, $event)"
                              [eventsDropDownMenu]="eventShowMenuSubject.asObservable()"></app-dropdown-menu>
                          </div>
                        </div>
                      </ng-template>
                      <ng-template #emptyMassCheck>
                        <div
                          class="px-1 table-dropdown-input"
                          *ngIf="paramsConf.type === typeEnum.INPUT; else emptyHeader">
                          <!-- <app-table-filter-input
                            [options]="optionsSearchModifiers[paramsConf.name].dropDown.dataItems"
                            [selectedItem]="optionsSearchModifiers[paramsConf.name].dropDown.selectedItem"
                            class="pba-dropdown-table"
                            (clickPbaDropdown)="onDropDownSearch(ih, $event)">
                          </app-table-filter-input> -->
                        </div>
                      </ng-template>
                      <ng-template #emptyHeader>
                        <div
                          [ngClass]="{
                            'wo-search': isSearchColumn,
                            'mb-3': !isSearchColumn
                          }"></div>
                      </ng-template>
                    </ng-template>
                  </ng-container>
                </div>
              </th>
            </tr>
          </thead>
          <tbody class="table-body">
            <tr
              *ngFor="
                let item of data
                  | paginate
                    : {
                        itemsPerPage: config.size,
                        currentPage: pageCurrent,
                        totalItems: config.count
                      };
                let ir = index
              "
              class="row-table">
              <td
                #tdItemRef
                style="z-index: 1"
                class="ps-2 column-table col{{ ic }}  "
                *ngFor="let paramsConf of config.tableConfigs; let ic = index; trackBy: trackByIndex"
                [ngSwitch]="paramsConf.type"
                [ngClass]="{
                  selected: paramsConf.selected,
                  'column-table-sticky': config.numColumnSticky > ic,
                  divEditable: paramsConf.isColumnEditable
                }">
                <div *ngSwitchCase="typeEnum.MASS_CHECK" class="body-data__check">
                  <div class="pba-check">
                    <input
                      class="pba-check-input"
                      id="{{ 'checkbox' + ir }}"
                      type="checkbox"
                      *ngIf="item?.['id']"
                      [checked]="checkedModel[ir].checked"
                      (click)="onClickMassCheck(item?.['id'] || -1)" />
                    <label class="pba-check-text" for="{{ 'checkbox' + ir }}"></label>
                    <label for="{{ 'checkbox' + ir }}" class="pba-check-mark"></label>
                  </div>
                </div>

                <div
                  #textItemRef
                  (click)="paramsConf.clickable?.link && onClickColumn(item, paramsConf)"
                  [ngClass]="{ 'select-column': paramsConf.clickable?.link }"
                  class="combo-editable"
                  *ngSwitchCase="isComboOrMultiple(paramsConf)"
                  [tooltip]="
                    ic !== 0
                      ? (item[paramsConf.tooltip] ||
                          ((paramsConf.data | filterjson : 'id' : item[paramsConf.name]) &&
                            (paramsConf.data | filterjson : 'id' : item[paramsConf.name]).name) | translate)
                      : null
                  "
                  [delay]="1500"
                  placement="right">
                  <div [ngStyle]="getColumnColorStyle(item, paramsConf)">
                    <app-spinner
                      *ngIf="
                        paramsConf.type === typeEnum.COMBO_LOADING &&
                        (item[paramsConf.name] === '' || item[paramsConf.name] === null)
                      "
                      [width]="10"
                      [height]="10"
                      class="d-flex justify-content-center">
                    </app-spinner>

                    <ng-container
                      *ngIf="
                        (paramsConf.data | filterjson : 'id' : item[paramsConf.name]) &&
                          (paramsConf.data | filterjson : 'id' : item[paramsConf.name]).name;
                        else elseBlock
                      ">
                      <span class="column-text d-block" *ngIf="!paramsConf.isColumnEditable || (!showInput[ic][ir] && paramsConf.isColumnEditable)"
                        >{{ (paramsConf.data | filterjson : 'id' : item[paramsConf.name]).name | translate }}
                        <i
                          *ngIf="
                            (paramsConf.isColumnEditable && item[paramsConf.name].enableColumnEditable) ||
                            (paramsConf.isColumnEditable && paramsConf.enableColumnEditable)
                          "
                          class="icon-edit-table-lg cursor-pointer finish edit-button elemento-a-mostrar"
                          (click)="onClickColumnEditable($event, item, paramsConf, ir, ic)"></i
                      ></span>

                      <combo-edit-table
                        *ngIf="paramsConf.isColumnEditable"
                        (save)="saveEditTable($event, ic, ir)"
                        (cancel)="cancelEditTable(ic, ir)"
                        [showInput]="showInput[ic][ir]"
                        [dataCombo]="paramsConf.data"
                        [isEditable]="paramsConf.isColumnEditable"
                        [data]="nameEditable"
                        [columnName]="columnName"></combo-edit-table>
                    </ng-container>

                    <ng-template #elseBlock>
                      <span>
                        <i
                          *ngIf="paramsConf.isColumnEditable"
                          class="icon-edit-table-lg cursor-pointer finish edit-button elemento-a-mostrar"
                          (click)="onClickColumnEditable($event, item, paramsConf, ir, ic)"></i
                      ></span>

                      <combo-edit-table
                        *ngIf="paramsConf.isColumnEditable"
                        (save)="saveEditTable($event, ic, ir)"
                        (cancel)="cancelEditTable(ic, ir)"
                        [showInput]="showInput[ic][ir]"
                        [dataCombo]="paramsConf.data"
                        [isEditable]="paramsConf.isColumnEditable"
                        [data]="nameEditable"
                        [topEmpty]="true"
                        [columnName]="columnName"></combo-edit-table>
                    </ng-template>

                    <span
                    class="column-text d-block"
                      *ngIf="item[paramsConf.name]?.constructor.name === 'String'"
                      [ngStyle]="getColumnColorStyle(item, paramsConf)">
                      {{ item[paramsConf.name] | translate }}
                    </span>
                  </div>
                </div>

                <span *ngSwitchCase="typeEnum.DATE_TIME">
                  <ng-container *ngIf="item[paramsConf.name]">
                    {{ item[paramsConf.name] | date : 'dd-MM-yyyy HH:mm' }}
                  </ng-container>
                </span>

                <span *ngSwitchCase="typeEnum.DATE_FILTER">
                  <ng-container *ngIf="item[paramsConf.name]">
                    {{ item[paramsConf.name] | date : 'dd-MM-yyyy HH:mm' }}
                  </ng-container>
                </span>

                <span *ngSwitchCase="typeEnum.DATE">
                  <ng-container *ngIf="item[paramsConf.name]">
                    {{ item[paramsConf.name] | date : 'dd-MM-yyyy' }}
                  </ng-container>
                </span>

                <span *ngSwitchCase="typeEnum.DATE_MONTH_FILTER">
                  <ng-container *ngIf="item[paramsConf.name]">
                    {{ item[paramsConf.name] }}
                  </ng-container>
                </span>
                <ng-container *ngSwitchDefault>
                  <div
                    (click)="paramsConf.clickable?.link && onClickColumn(item, paramsConf)"
                    [ngClass]="{ 'select-column': paramsConf.clickable?.link }"
                    #textItemRef
                    class="column-text"
                    [tooltip]="ic !== 0 ? item[paramsConf.tooltip] || item[paramsConf.name] : ''"
                    [delay]="1500"
                    placement="right">
                    <span
                      *ngIf="!paramsConf.isColumnEditable || (!showInput[ic][ir] && paramsConf.isColumnEditable)"
                      [ngStyle]="getColumnColorStyle(item, paramsConf)"
                      >{{ getDataByColumnName(ir, paramsConf.name) }}
                      <i
                        *ngIf="paramsConf.isColumnEditable"
                        class="icon-edit-table-lg cursor-pointer finish edit-button elemento-a-mostrar"
                        (click)="onClickColumnEditable($event, item, paramsConf, ir, ic)"></i>
                    </span>
                  </div>

                  <input-edit-table
                    *ngIf="paramsConf.isColumnEditable"
                    (save)="saveEditTable($event, ic, ir)"
                    (cancel)="cancelEditTable(ic, ir)"
                    [showInput]="showInput[ic][ir]"
                    [isEditable]="paramsConf.isColumnEditable"
                    [data]="nameEditable"
                    [columnName]="columnName"></input-edit-table>
                </ng-container>

                <ng-container *ngSwitchCase="typeEnum.TRANSLATE">
                  <div
                    #textItemRef
                    class="column-text"
                    [tooltip]="ic !== 0 ? item[paramsConf.tooltip] || item[paramsConf.name] : ''"
                    [delay]="1500"
                    placement="right">
                    <span [ngStyle]="getColumnColorStyle(item, paramsConf)">
                      {{ item[paramsConf.name] | translate }}
                    </span>
                  </div>
                </ng-container>

                <ng-container *ngSwitchCase="typeEnum.LINK">
                  <span class="link cursor-pointer" (click)="onSelectedLink(item, paramsConf.name)">{{
                    item[paramsConf.name]
                  }}</span>
                </ng-container>

                <div *ngSwitchCase="typeEnum.ACTIONS">
                  <ng-container
                    *ngFor="let action of paramsConf.actions; index as i; trackBy: trackByIndex"
                    [ngSwitch]="action">
                    <ng-container *ngIf="checkShowIcon(ir, item, paramsConf, action) && action !== 'options'">
                      <ng-container *ngIf="action === 'switch-on' || action === 'switch-off'; else iconBlock">
                        <button
                          type="button"
                          class="btn-accordion btn-sm btn-toggle btn-toggle-small btn-toggle-base-green-red"
                          [ngClass]="action === 'switch-on' ? 'active' : ''"
                          (click)="onSelectedAction({ action: action, data: item, index: ir })">
                          <div class="handle"></div>
                        </button>
                      </ng-container>
                      <ng-template #iconBlock>
                        <span class="cursor-pointer s-icons">
                          <i
                            data-toggle="tooltip"
                            title="{{ action | translate }}"
                            *ngSwitchCase="action"
                            class="icon-{{ getAction(item, action, paramsConf) }}-md"
                            (click)="
                              onSelectedAction({
                                action: action,
                                data: item,
                                index: ir
                              })
                            "></i>
                        </span>
                      </ng-template>
                    </ng-container>

                    <ng-container *ngIf="checkShowIcon(ir, item, paramsConf, action) && action === 'options'">
                      <span class="cursor-pointer s-icons">
                        <i
                          class="dots-options"
                          (click)="onClick3DotsAction(ir, item)"
                          data-toggle="tooltip"
                          title="{{ action | translate }}"
                          >...</i
                        >
                        <app-portal [reset]="ir === 0">
                          <app-dropdown-menu
                            [options]="item.dotOptions"
                            (clickPbaDropdownMenu)="onDropDownMenu(ir, $event)"
                            [eventsDropDownMenu]="dropdownsEvent[ir]"></app-dropdown-menu>
                        </app-portal>
                      </span>
                    </ng-container>
                  </ng-container>
                </div>

                <ng-container *ngSwitchCase="typeEnum.STATE">
                  <div
                    *ngIf="item[paramsConf.name]"
                    class="d-flex align-item-center"
                    [ngClass]="{ 'ms-2': !item[paramsConf.name]['des'] }">
                    <i class="d-inline-block" [ngClass]="statesClasses(item[paramsConf.name]['color'])"></i>
                    <span *ngIf="item[paramsConf.name]['des']" class="ms-1">
                      {{ item[paramsConf.name]['des'] | translate }}
                    </span>
                  </div>
                </ng-container>

                <div *ngIf="typeEnum.COMBO_BOOLEAN === paramsConf.type && paramsConf.isStateBoolean">
                  <ng-container *ngSwitchCase="typeEnum.COMBO_BOOLEAN">
                    <div
                      *ngIf="item[paramsConf.name]"
                      class="d-flex align-item-center"
                      [ngClass]="{ 'ms-2': !item[paramsConf.name]['des'] }">
                      <i class="d-inline-block" [ngClass]="statesClasses(item[paramsConf.name]['color'])"></i>
                      <span *ngIf="item[paramsConf.name]['des']" class="ms-1">
                        {{ item[paramsConf.name]['des'] | translate }}
                      </span>
                    </div>
                  </ng-container>
                </div>

                <div *ngIf="(typeEnum.COMBO_MULTIPLE === paramsConf.type && paramsConf.isStateBoolean)">
                  <ng-container *ngSwitchCase="typeEnum.COMBO_MULTIPLE">
                    <div
                      *ngIf="item[paramsConf.name]"
                      class="d-flex align-item-center"
                      [ngClass]="{ 'ms-2': !item[paramsConf.name]['des'] }">
                      <i class="d-inline-block" [ngClass]="statesClasses(item[paramsConf.name]['color'])"></i>
                      <span *ngIf="item[paramsConf.name]['des']" class="ms-1">
                        {{ item[paramsConf.name]['des'] | translate }}
                      </span>
                    </div>
                  </ng-container>
                </div>

                <div *ngIf="(typeEnum.STATUS_FIELD === paramsConf.type && paramsConf.isStateBoolean)">
                  <ng-container *ngSwitchCase="typeEnum.STATUS_FIELD">
                    <div
                    data-bs-toggle="tooltip"
                    title="{{ item['stateDesc']  | translate}}"
                      *ngIf="item[paramsConf.name]"
                      class="d-flex align-item-center"
                      [ngClass]="{ 'ms-2': !item[paramsConf.name]['des'] }">
                      <i class="d-inline-block" [ngClass]="statesClasses(item[paramsConf.name]['color'])"></i>
                      <span *ngIf="item[paramsConf.name]['des']" class="ms-1">
                        {{ item[paramsConf.name]['des'] | translate }}
                      </span>
                    </div>
                  </ng-container>
                </div>
              </td>
            </tr>

            <ng-container *ngIf="config.fillinTable">
              <tr *ngFor="let item of filling; trackBy: trackByIndex" class="row-table">
                <td
                  *ngFor="let paramsConf of config.tableConfigs; trackBy: trackByIndex"
                  class="ps-2 column-table"></td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <div [ngClass]="{ 'axess': domainAxess, 'nms':domainNms }">
  <div class="head-pagination" *ngIf="config.count > 0 && config.showPagination">
    <div class="d-flex justify-content-end my-2">
      <pagination-template #p="paginationApi" (pageChange)="pageChanged($event)" [maxSize]="7">
        <div class="pagination-previous d-flex" *ngIf="p && p.pages && p.pages.length > 1">
          <div
            class="page-number"
            *ngFor="let page of p.pages; trackBy: trackByIndex"
            [class.current]="p.getCurrent() === page.value">
            <button
              (click)="p.setCurrent(page.value)"
              class="cl-alert pagination pagination-number btn-outline btn pba-btn-sm d-flex justify-content-center align-items-center"
              [ngClass]="{ active: p.getCurrent() === page.value }">
              {{ page.label }}
            </button>
          </div>
          <button
            class="pagination pagination-selection btn btn-primary pba-btn-sm d-flex justify-content-center align-items-center"
            [class.disabled]="p.isFirstPage()"
            [disabled]="p.isFirstPage()"
            (click)="pagePrevious()">
            <i class="icon-semi-left-arrow-lg"></i>
          </button>
          <button
            class="pagination pagination-selection btn btn-primary pba-btn-sm d-flex justify-content-center align-items-center"
            [class.disabled]="p.isLastPage()"
            [disabled]="p.isLastPage()"
            (click)="pageNext()">
            <i class="icon-semi-right-arrow-lg"></i>
          </button>
        </div>
      </pagination-template>
    </div>
  </div>
</div>
</div>
