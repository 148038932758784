import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ClickOutsideDirectiveModule } from '@shared/directives/ClickOutsideDirective/click-outside-directive.module';
import { DropdownMenuComponent } from './dropdown-menu.component';
@NgModule({
  declarations: [DropdownMenuComponent],
  imports: [CommonModule, TranslateModule, FormsModule, TooltipModule.forRoot(), ClickOutsideDirectiveModule],
  exports: [DropdownMenuComponent]
})
export class DropDownMenuModule {}
