<div class="modal-content align-items-center">
  <div class="modal-header">
    <h6 align="center" class="modal-title" id="exampleModalLabel">
      {{ 'COMMONS.CONFIG_TABLE' | translate }}
    </h6>

    <button class="btn btn-primary btn-sm btnn-syncro btn-position-modal" (click)="reset()">
      <span class="icon-undo-lg" data-bs-toggle="tooltip" title="{{ 'TOOLTIPS.RESET' | translate }}"></span>
    </button>
    <button
      type="button"
      class="close btn-close btn-position-modal btn-close"
      data-dismiss="modal"
      aria-label="Close"
      (click)="bsModalRef.hide()">
      <span aria-hidden="true" class="visually-hidden">&times;</span>
    </button>
  </div>

  <div class="modal-body modal-column text-center p-0">
    <div cdkDropListGroup class="row cdk-group">
      <div class="col-6">
        <span class="col-form-label pba-input-label">{{ 'COMMONS.COLUMS' | translate }}</span>
        <div cdkDropList [cdkDropListData]="filterColumnList" class="cdk-container" (cdkDropListDropped)="drop($event)">
          <div class="cdk-box" *ngFor="let item of filterColumnList; trackBy: trackByIndex" cdkDrag>
            {{ item.name }}
          </div>
        </div>
      </div>
      <div class="col-6">
        <span class="col-form-label pba-input-label">{{ 'COMMONS.SELECTEDS' | translate }}</span>
        <div
          cdkDropList
          [cdkDropListData]="columnsByUser"
          class="cdk-container"
          (cdkDropListDropped)="drop($event)"
          [ngClass]="{
            'is-invalid': emptySelection
          }">
          <div class="cdk-box" *ngFor="let item of columnsByUser; trackBy: trackByIndex" cdkDrag>
            {{ item.name }}
          </div>
        </div>
        <p class="text-error" *ngIf="emptySelection">
          {{ 'COMMONS.EMPTY_COLUNMS' | translate }}
        </p>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline pba-btn-md cl-alert" (click)="onCancel()">
      {{ 'COMMONS.DECLINE' | translate }}
    </button>
    <button type="button" class="btn btn-primary pba-btn-md cl-alert" (click)="onConfirm()">
      {{ 'COMMONS.ACCEPT' | translate }}
    </button>
  </div>
</div>
