<div  [ngClass]="{'axess': domainAxess, 'nms':domainNms}" *ngIf="showFavoriteList || hasFilter">
  <i
    class="btn btn-sm icon-star-outline-blue-md button-star-outline"
    data-bs-toggle="dropdown"
    aria-expanded="false"
    aria-haspopup="true"
    title="{{ 'COMPONENT.TABLE.FAVORITE.ADD' | translate }}"></i>

  <ul class="dropdown-menu favorite-menu" aria-labelledby="dropdownMenuClickableInside">
    <li *ngIf="hasFilter">
      <div style="display: flex; justify-content: center; align-items: center; margin: 8px">
        <button
          class="btn btn-primary btn-sm icon-plus-blue-xl"
          style="width: 100%"
          (click)="onNewFavorite()"
          title="{{ 'COMPONENT.TABLE.FAVORITE.ADD_FILTER' | translate }}"></button>
      </div>
    </li>

    <li *ngIf="hasFilter && favoriteList.length > 0">
      <hr class="dropdown-divider" />
    </li>

    <li *ngFor="let item of favoriteList; trackBy: trackByIndex">
      <div class="item-list">
        <a class="dropdown-item" (click)="onSelectFavorite(item)" title="{{ item.name }}">{{ item.name }}</a>
        <i
          data-toggle="tooltip"
          class="icon-delete-blue-xl ng-star-inserted"
          (click)="onDeleteFavorite(item)"
          title="{{ 'COMPONENT.TABLE.FAVORITE.DELETE_FILTER' | translate }}"></i>
      </div>
    </li>
  </ul>
</div>
