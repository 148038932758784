export enum TypeEnum {
  COMBO = 'combo',
  DATE = 'date',
  DATE_TIME = 'dateTime',
  DATE_FILTER = 'dateFilter',
  DATE_MONTH_FILTER = 'dateMonthFilter',
  LINK = 'link',
  ACTIONS = 'actions',
  STATE = 'state',
  TRANSLATE = 'translate',
  COMBO_LOADING = 'comboPlainLoading',
  MASS_CHECK = 'mass_check',
  INPUT = 'input',
  COMBO_MULTIPLE = 'combo_multiple',
  COMBO_BOOLEAN = 'combo_boolean',
  STATUS_FIELD = 'status_field'
}
