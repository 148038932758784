import * as _ from 'lodash';
declare module 'lodash' {
  interface LoDashStatic {
    isEmptyValue(a: any): boolean;
    removeEmpty(obj: any): any;
    getGroupArrayEveryXItems(data: Array<any>, column: number): any;
  }
}

/**
 * Devuelve si contiene un valor.
 * @param value
 * @returns
 */
function isEmptyValue(value: any): boolean {
  return (
    value === undefined ||
    value === null ||
    _.isNaN(value) ||
    (typeof value === 'object' && Object.keys(value).length === 0) ||
    (_.isString(value) && value.length === 0)
  );
}

/**
 * Borra los vacíos de un json.
 * @param obj
 * @returns
 */
function removeEmpty(obj: any) {
  if (obj === null || obj === undefined) {
    return obj;
  }
  Object.entries(obj).forEach(([key, val]) => {
    obj[key] = _.isString(val) ? val.trim() : val;
    return (
      (val && typeof val === 'object' && removeEmpty(val)) ||
      ((val === null || (_.isString(val) && val.length === 0)) && delete obj[key])
    );
  });
  return obj;
}

function getGroupArrayEveryXItems(data: Array<any>, column = 2) {
  const newArray = [];
  for (let i = 0; i < data.length; i += column) {
    newArray.push(data.slice(i, i + column));
  }
  return newArray;
}

_.mixin({
  isEmptyValue,
  removeEmpty,
  getGroupArrayEveryXItems
});
