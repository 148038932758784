import { TableConfig } from './table-config.model';
import { Pageable } from './pageable.model';

export class ConfigParamsTable extends Pageable {
  /**
   *
   * @param tableConfigs header configuration parameters per column
   * @param fillinTable if the table should be completed up to the number of elements per page.
   * @param count number of total items in the table
   * @param columnSelectDefault column select by default with order ASC.
   * @param showScroll show scroll
   * @param showPagination show pagination
   * @param showSecondRowHeaderFilterOptions show second row in header with filter options
   * @param numColumnSticky number of fixed columns in horizontal scroll.
   * @param isColumnClick Allow click on the head of the column.
   */
  constructor(
    public tableConfigs = new Array<TableConfig>(),
    public fillinTable: boolean = true,
    public count: number = 0,
    public columnSelectDefault?: number,
    public showScroll = true,
    public showPagination = true,
    public showSecondRowHeaderFilterOptions = true,
    public numColumnSticky = 1,
    public isColumnClick = false,
    public columnsId = new Array<number>(),
    public module = ''
  ) {
    super(0, 10);
  }
}
