import { createAction, props } from '@ngrx/store';
import { DeviceInfo, NetworkInfo, TerminalInfo } from '../model';

export enum TerminalWifiActionTypes {
  terminalWifiStoreClear = '[TERMINAL WIFI STORE CLEAR] CLEAR terminal wifi store clear',

  terminalWifiInfoLoad = '[TERMINAL WIFI INFO GET] REQUEST terminal wifi info get',
  terminalWifiInfoLoadSuccess = '[TERMINAL WIFI INFO GET SUCCESS] RESPONSE terminal wifi network info get',

  terminalWifiNetworkChangeStatus = '[TERMINAL WIFI NETWORK STATUS PUT] REQUEST terminal wifi PUT',
  terminalWifiNetworkChangeStatusSuccess = '[TERMINAL WIFI NETWORK STATUS CHANGE PUT SUCCESS] RESPONSE terminal wifi put',

  terminalWifiNetworkLoad = '[TERMINAL WIFI NETWORK GET] REQUEST terminal wifi get',
  terminalWifiNetworkLoadSuccess = '[TERMINAL WIFI NETWORK GET SUCCESS] RESPONSE terminal wifi network get success',
  terminalWifiDeviceLoad = '[TERMINAL WIFI DEVICE GET SUCCESS] REQUEST terminal wifi device get',
  terminalWifiDeviceLoadSuccess = '[TERMINAL WIFI DEVICE GET SUCCESS] RESPONSE terminal wifi device get success',
  terminalWifiChangePasswordPut = '[TERMINAL WIFI CHANGE PASSWORD PUT] REQUEST terminal wifi change password put',
  terminalWifiChangePasswordPutSuccess = '[TERMINAL WIFI CHANGE PASSWORD PUT SUCCESS] RESPONSE terminal wifi change password put success',
  terminalWifiResetValuesPut = '[TERMINAL WIFI RESET VALUES PUT] REQUEST terminal reset values',
  terminalWifiResetValuesPutSuccess = '[TERMINAL WIFI RESET VALUES PUT SUCCESS] REQUEST terminal reset values success'
}

export const terminalWifiStoreClear = createAction(TerminalWifiActionTypes.terminalWifiStoreClear);

export const terminalWifiInfoLoad = createAction(
  TerminalWifiActionTypes.terminalWifiInfoLoad,
  props<{ terminalId: number }>()
);
export const terminalWifiInfoLoadSuccess = createAction(
  TerminalWifiActionTypes.terminalWifiInfoLoadSuccess,
  props<{ info: TerminalInfo }>()
);

export const terminalWifiNetworkChangeStatus = createAction(
  TerminalWifiActionTypes.terminalWifiNetworkChangeStatus,
  props<{ terminalId: number; network: string; status: string }>()
);
export const terminalWifiNetworkChangeStatusSuccess = createAction(
  TerminalWifiActionTypes.terminalWifiNetworkChangeStatusSuccess,
  props<{ network: string; status: string }>()
);

export const terminalWifiNetworkLoad = createAction(
  TerminalWifiActionTypes.terminalWifiNetworkLoad,
  props<{ terminalId: number }>()
);
export const terminalWifiNetworkLoadSuccess = createAction(
  TerminalWifiActionTypes.terminalWifiNetworkLoadSuccess,
  props<{ network: NetworkInfo[] }>()
);

export const terminalWifiDeviceLoad = createAction(
  TerminalWifiActionTypes.terminalWifiDeviceLoad,
  props<{ terminalId: number }>()
);
export const terminalWifiDeviceLoadSuccess = createAction(
  TerminalWifiActionTypes.terminalWifiDeviceLoadSuccess,
  props<{ devices: DeviceInfo[] }>()
);

export const terminalWifiChangePasswordPut = createAction(
  TerminalWifiActionTypes.terminalWifiChangePasswordPut,
  props<{ terminalId: number; password: string }>()
);
export const terminalWifiChangePasswordPutSuccess = createAction(
  TerminalWifiActionTypes.terminalWifiChangePasswordPutSuccess,
  props<{ changePassword: boolean }>()
);

export const terminalWifiResetValuesPut = createAction(
  TerminalWifiActionTypes.terminalWifiResetValuesPut,
  props<{ terminalId: number }>()
);
export const terminalWifiResetValuesPutSuccess = createAction(
  TerminalWifiActionTypes.terminalWifiResetValuesPutSuccess,
  props<{ resetValues: boolean }>()
);
