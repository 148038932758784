import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-spinner-overlay',
  templateUrl: './spinner-overlay.component.html',
  styleUrls: ['./spinner-overlay.component.scss']
})
export class SpinnerOverlayComponent implements OnInit {
  active = false;
  @Input() public message?: string;

  ngOnInit() {
    setTimeout(() => {
      this.active = true;
    }, 50);
  }
}
