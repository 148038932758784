import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableComponent } from './table.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { DropDownModule } from '../dropdown/dropdown.module';
import { TranslateModule } from '@ngx-translate/core';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { FormsModule } from '@angular/forms';
import { SpinnerModule } from '../spinner/spinner.module';
import { DropDownMenuModule } from '../dropdown-menu/dropdown-menu.module';
import { DatePickerSearchModule } from '@shared/components/date-picker-search/date-picker-search.module';
import { CustomPortalModule } from '@shared/components/portal/portal.module';
import { PortalModule } from '@angular/cdk/portal';
import { KeysPipe } from '@shared/pipes/keys.pipe/keys.pipe';
import { FilterJsonPipe } from '@shared/pipes/filter-json.pipe/filter-json.pipe';
import { FavoritesComponent } from './components/favorites/favorites.component';
import { ModalAddFavoriteComponent } from './components/modal-add-favorite/modal-add-favorite.component';
import { TableFilterInputComponent } from './components/table-filter-input/table-filter-input.component';
import { TableFilterSelectMultiComponent } from './components/table-filter-select-multi/table-filter-select-multi.component';
import { TableFilterSelectUniqueComponent } from './components/table-filter-select-unique/table-filter-select-unique.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { AngularSvgIconPreloaderModule } from '@vendor/angular-svg-icon-preloader/angular-svg-icon-preloader.module';
import { TableFilterSelectBooleanComponent } from './components/table-filter-select-boolean/table-filter-select-boolean.component';
import { DoubleClickDirectiveModule } from '@shared/directives/DoubleClickDirective/double-click.directive.module';
import { ResizeDirectiveModule } from '@shared/directives/ResizeDirective/resize-directive.module';
import { TableShareSearchComponent } from './components/table-share-search/table-share-search.component';
import { TableShareSearchModalComponent } from './components/table-share-search-modal/table-share-search-modal.component';
import { InputEditTableModule } from '@shared/components/input-edit-table/input-edit-table.module';
import { ComboEditTableModule } from '@shared/components/combo-edit-table/combo-edit-table.module';
import { ClickOutsideDirectiveModule } from '@shared/directives/ClickOutsideDirective/click-outside-directive.module';
import { ScrollOutsideDirectiveModule } from '@shared/directives/ScrollOutsideDirective/scroll-outside-directive.module';

@NgModule({
  declarations: [
    TableComponent,
    FilterJsonPipe,
    KeysPipe,
    FavoritesComponent,
    ModalAddFavoriteComponent,
    TableFilterInputComponent,
    TableFilterSelectMultiComponent,
    TableFilterSelectUniqueComponent,
    TableFilterSelectBooleanComponent,
    TableShareSearchComponent,
    TableShareSearchModalComponent
  ],
  imports: [
    CommonModule,
    NgxPaginationModule,
    DropDownModule,
    DropDownMenuModule,
    InputEditTableModule,
    ComboEditTableModule,
    SpinnerModule,
    FormsModule,
    TranslateModule,
    TooltipModule.forRoot(),
    DatePickerSearchModule,
    CustomPortalModule,
    PortalModule,
    AngularSvgIconModule,
    AngularSvgIconPreloaderModule.forRoot({
      configUrl: '../../../assets/icons/svg-icons.json'
    }),
    DoubleClickDirectiveModule,
    ResizeDirectiveModule,
    ClickOutsideDirectiveModule,
    ScrollOutsideDirectiveModule
  ],
  exports: [TableComponent]
})
export class TableModule {}
