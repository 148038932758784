import { RoleEnum } from './role.enum';

export const roleWightUser: any = {
  [RoleEnum.ROLE_ADMIN]: 1,
  [RoleEnum.ROLE_ADMIN_VNO]: 2,
  [RoleEnum.ROLE_SLOT]: 3,
  [RoleEnum.ROLE_ADMIN_CUENTAS]: 4,
  [RoleEnum.ROLE_EXTERNAL_CUENTAS]: 5,
  [RoleEnum.ROLE_TERMINAL]: 6,
  [RoleEnum.ROLE_TECHNICAL_SUPPORT]: 7,
  [RoleEnum.ROLE_MONITORING_RELOAD_BLOCK_SDHD]: 8,
  [RoleEnum.ROLE_MONITORING_RELOAD_BLOCK]: 9,
  [RoleEnum.ROLE_MONITORING_RELOAD]: 10,
  [RoleEnum.ROLE_MONITORING]: 11,
  [RoleEnum.ROLE_INSTALLER]: 12
};
