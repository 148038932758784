import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'keys' })
export class KeysPipe implements PipeTransform {
  transform(value: any): any {
    const keys = [];
    delete value.id;
    for (const key in value) {
      keys.push(key);
    }
    return keys;
  }
}
