import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'input-edit-table',
  templateUrl: './input-edit-table.component.html',
  styleUrls: ['./input-edit-table.component.scss']
})
export class InputEditTableComponent  implements  OnChanges  {
 
  @Input() showInput = false;
  @Input() isEditable = false;
  @Input() data : any ;
  @Input() columnName : any ;
  @Output() toggled: EventEmitter<void> = new EventEmitter<void>();
  @Output() save: EventEmitter<void> = new EventEmitter<void>();
  @Output() cancel: EventEmitter<void> = new EventEmitter<void>();

  isEmpty : boolean = false;


  ngOnChanges(changes: SimpleChanges): void {
    this
    changes
  }

  onClickSave(){
    this.isEmpty = false;
    let data : any = {"data":this.data, "column": this.columnName};
    this.save.emit(data);
  }

  onClickDelete(){
    this.isEmpty = false;
    this.cancel.emit();
  }

  onInputChange(event: any): void {
    if(this.data?.name == ""){
      this.isEmpty = true;
    }else{
      this.isEmpty = false;
    }
  }


}
