import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { SharedSearchService } from '../../services/shared-search.service';

@Component({
  selector: 'app-table-share-search-modal',
  templateUrl: './table-share-search-modal.component.html',
  styleUrls: ['./table-share-search-modal.component.scss']
})
export class TableShareSearchModalComponent implements OnInit, OnDestroy {
  @Input() module: any;
  @Input() query: any;

  modalRef!: BsModalRef;
  onCloseSubject$: Subject<unknown | undefined> = new Subject();
  url = '';
  hasUrl = false;
  hasUrlCopy = false;

  @ViewChild('favoriteInput', { static: false }) favoriteInput: any;

  constructor(
    public bsModalRef: BsModalRef,
    private readonly translate: TranslateService,
    private sharedSearchService: SharedSearchService
  ) {
    this.translate.use(this.translate.store.currentLang);
    this.translate.store.onLangChange.subscribe(lang => this.translate.use(lang.lang));
  }

  ngOnInit(): void {
    const body = { query: JSON.stringify(this.query) };
    this.sharedSearchService.newSharedSearch(this.module, body).subscribe((response: any) => {
      if (response.status == 200) {
        this.hasUrl = true;
        this.url = `${window.location.origin}${window.location.pathname}?search=${response.data}`;
      }
    });
  }

  ngOnDestroy() {
    if (this.bsModalRef) this.bsModalRef.hide();
  }

  clipboardCopy() {
    this.hasUrlCopy = true;
    navigator.clipboard.writeText(this.url);
  }
}
