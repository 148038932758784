import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from '@environments/environment';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { AuthenticationService } from '@services/authentication.service/authentication.service';
import { DomainService } from '@shared/services/domain/domain.service';
import { DomainsEnum } from '@shared/models/common/domains.enum';
import dayjs from 'dayjs';

const jwtHelper = new JwtHelperService();

@Injectable()
export class JwtOatuh2Interceptor implements HttpInterceptor {
  constructor(private authenticationService: AuthenticationService, private router: Router) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.startsWith(environment.backendUrl + '/azure')) {
      const jwt = this.authenticationService.jwt.value;
      const isLoggedIn = jwt && jwt.access_token;
      const isBackendUrl = request.url.startsWith(environment.backendUrl);
      if (jwt && jwt.access_token && !jwtHelper.isTokenExpired(jwt.access_token)) {
        const expirationTime = dayjs(jwtHelper.getTokenExpirationDate(jwt.access_token));
        const diffExpDate = expirationTime.diff(dayjs(), 'millisecond');
        if (diffExpDate <= 0) {
          this.authenticationService.logout();
        }

        if (isLoggedIn && isBackendUrl) {
          request = request.clone({
            setHeaders: {
              Authorization: `Bearer ${jwt.access_token}`
            }
          });
        }
      } else {
        this.router.navigate(['/axess']);
      }
    }
    return next.handle(request);
  }
}
