import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ConfigTableService {
  constructor(private http: HttpClient) {}

  getConfigByModule(code: any) {
    return this.http.get<any>(environment.backendUrl + '/config-table-view/' + code, {});
  }

  getConfigByUser(code: any) {
    return this.http.get<any>(environment.backendUrl + '/config-table-view/' + code + '/byuser', {});
  }

  createConfig(code: any, configToSave: any) {
    return this.http.post<any>(environment.backendUrl + '/config-table-view/' + code, configToSave, {
      observe: 'body',
      responseType: 'json'
    });
  }

  reset(code: any) {
    return this.http.delete<any>(environment.backendUrl + '/config-table-view/' + code, {});
  }
}
