<div
  class="modal fade"
  id="exampleModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
  (click)="cancelar(1)">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="icon-position">
        <i class="icon-help-lg size-icon"></i>
      </div>
      <div class="modal-header">
        <h6 align="center" class="modal-title" id="exampleModalLabel">
          {{ textToShow }}
        </h6>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline pba-btn-md" (click)="cancelar(1)" data-bs-dismiss="modal">
          {{ 'COMMONS.DECLINE' | translate }}
        </button>
        <button type="button" class="btn btn-primary pba-btn-md" (click)="emitir(1)" data-bs-dismiss="modal">
          {{ 'COMMONS.ACCEPT' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
