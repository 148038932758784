<div class="px-1 max-width-col">
  <div class="dropdown pba-dropdown w-100 d-flex pba-input-block justify-content-start">
    <!-- Criteria -->
    <div data-bs-toggle="dropdown" class="pba-input-block__icons over-icons" (click)="toggleDropDown()">
      <svg-icon
        class="pba-input-block__icon-selected d-flex"
        name="{{ criteriaDropDown.dataItems[criteriaSelIndex].svgIcon?.name }}"
        [svgStyle]="{ 'width.px': 28, 'height.px': 18 }">
      </svg-icon>
      <div class="pba-input-block__icon">
        <i class="pba-input-block__icon--pos"></i>
      </div>
    </div>
    <div #dropdownRef (scroll)="onScroll()" class="dropdown-menu p-0" tabindex="0">
      <div class="search-select2">
        <input
          type="text"
          #criteriaSearch
          class="pba-input-search m-1 w-100"
          [(ngModel)]="criteriaSearchValue"
          (blur)="onBlurInputSearch()"
          (ngModelChange)="onChangeTextSearch($event)"
          (keydown.arrowdown)="onKeyDownArrowDown()"
          (keydown.arrowup)="onKeyDownArrowUp()"
          (keydown.enter)="onKeyUpSelected($event)" />
      </div>
      <ul class="menu-selec2 p-0">
        <ng-container *ngFor="let item of criteriaDropDown.dataItems; let i = index; trackBy: trackById">
          <li
            *ngIf="item.show"
            #dropdownItemRef
            class="li-dropdown dropdown-item d-flex"
            id="{{ item.id }}"
            (click)="onClickCriteria(item, $event, i)"
            translate
            [translateParams]="{ params: item.nameParams }"
            title="{{ item.name }}"
            [ngClass]="{ focus: item.focus }">
            <svg-icon
              *ngIf="item.svgIcon"
              class="me-1"
              name="{{ item.svgIcon.name }}"
              [svgStyle]="{
                'width.px': item?.svgIcon?.width || 28,
                'height.px': item?.svgIcon?.height,
                fill: item?.svgIcon?.fill
              }">
            </svg-icon>
            {{ item.name }}
          </li>
        </ng-container>
      </ul>
    </div>

    <!-- Calendar -->

    <!-- real input with directive -->
    <input
      bsDatepicker
      style="visibility: hidden"
      #dp="bsDatepicker"
      type="text"
      [(ngModel)]="selectedDate"
      class="form-control"
      [bsConfig]="datePickerBsConfig"
      [bsValue]="selectedDate"
      (onShown)="onOpenCalendar($event)"
      [maxDate]="maxDate"
      [minDate]="minDate"
      (onHidden)="emitSearchEvent()"
      (bsValueChange)="onbsDatepickerValueChange($event)" />

    <!-- fake input over real to overwrite open modal clicking calendar icon instead focus -->
    <span class="icon-calendar-lg pos-icon over-icons" (click)="dp.show()"></span>
    <input
      #myid
      [value]="selectedDate | date : 'dd-MM-yyyy'"
      (keyup)="updateInputDelay$.next($event)"
      [ngClass]="{ 'is-invalid': dateInvalid }"
      type="text"
      class="form-control pba-input-text over-input" />
    <div (click)="clearfilters(true)" *ngIf="showButtonClearFilters" class="button-clear-filters">x</div>
  </div>
</div>
