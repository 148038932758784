export class PortalsUtils {
  static closeDropdown() {
    setTimeout(() => {
      const dropDown = document.getElementsByClassName('dropdown-menu d-block')[0] as any;
      if (dropDown) {
        const dropDownOpen = dropDown.children.length === 1;
        if (dropDownOpen) {
          dropDown.style.visibility = 'hidden';
        }
      }
    }, 0);
  }
}
