import { Action, createReducer, on } from '@ngrx/store';
import { adapter, HeadState } from './head.adapter';

import * as fromActions from './head.actions';

export const initialState: HeadState = adapter.getInitialState({
  selectLanguageId: -1,
  loggedIn: false
});

const headReducer = createReducer(
  initialState,
  on(fromActions.setLanguages, (state: HeadState, { languages }) => {
    return adapter.setAll(languages, state);
  }),
  on(fromActions.selectLanguage, (state: HeadState, { id }) => {
    return { ...state, selectLanguageId: id };
  }),
  on(fromActions.setLoggedInState, (state: HeadState) => {
    return { ...state, loggedIn: true };
  })
);

export function reducer(state: HeadState | undefined, action: Action) {
  return headReducer(state, action);
}
