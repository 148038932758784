import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { TableConfigurationBarModule } from './table-configuration-bar/table-configuration-bar.module';
import { DropDownModule } from './dropdown/dropdown.module';
import { ModalConfirmComponent } from './modal/modal-confirm/modal-confirm.component';
import { ModalColumnComponent } from './modal/modal-column/modal-column.component';
import { ModalComponent } from './modal/modal.component';
import { SpinnerModule } from './spinner/spinner.module';
import { TableModule } from './table/table.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InvalidControlScrollDirective } from '../directives/invalid-control-scroll.directive/invalid-control-scroll.directive';
import { DragDropModule } from '@angular/cdk/drag-drop';

@NgModule({
  declarations: [ModalConfirmComponent, ModalColumnComponent, ModalComponent, InvalidControlScrollDirective],
  imports: [CommonModule, TranslateModule, DragDropModule],
  exports: [
    CommonModule,
    TranslateModule,
    TableModule,
    TableConfigurationBarModule,

    ModalComponent,
    ModalConfirmComponent,
    ModalColumnComponent,

    DropDownModule,

    SpinnerModule,
    FormsModule,
    ReactiveFormsModule,
    InvalidControlScrollDirective
  ]
})
export class SharedModule {}

// Note:TranslateModule, never call a forRoot static method in the SharedModule.You might end up with different instances of the service in your injector tree.But you can use forChild if necessary.
// When you lazy load a module, you should use the forChild static method to import the TranslateModule.
