import { RoleEnum } from '../models/users/role.enum';
import { ClientTypeEnum } from '../models/client/client-type.enum';
import { AuthenticationService } from '../../services/authentication.service/authentication.service';

export class RoleUserClient {
  constructor(protected authenticationService: AuthenticationService) {}

  checkUserRole(minRole: RoleEnum) {
    return this.authenticationService.checkUserRole(minRole);
  }

  checkUserRoleMax(maxRole: RoleEnum) {
    return this.authenticationService.checkUserRoleMax(maxRole);
  }

  checkClientTypeRole(minRole: ClientTypeEnum) {
    return this.authenticationService.checkClientTypeRole(minRole);
  }

  checkClientTypeRoleMax(maxRole: ClientTypeEnum) {
    return this.authenticationService.checkClientTypeRoleMax(maxRole);
  }

  checkSearchAdmin(search: any) {
    if (this.authenticationService.checkClientTypeRoleNotAdmin()) {
      search['clientId'] = this.authenticationService.currentUser.value.client.clientId;
    }
  }

  checkUserOnlyRole(role: RoleEnum) {
    return this.authenticationService.checkUserOnlyRole(role);
  }

  checkUserOnlyTypeClientRole(role: ClientTypeEnum) {
    return this.authenticationService.checkUserOnlyTypeClientRole(role);
  }

  hasChildren(): boolean {
    return this.authenticationService.currentUser.value.client.hasChilds;
  }
}
