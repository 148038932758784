import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EffectsModule } from '@ngrx/effects';
import { TranslateModule } from '@ngx-translate/core';
import { defineLocale, esLocale } from 'ngx-bootstrap/chronos';
import { LoginComponent } from './login.component';
import { LoginEffects } from './store/login.effects';
import { LoginOauth2Component } from './login-oauth2/login-oauth2.component';
import { ErrorOauth2Component } from './login-oauth2/error-oauth2/error-oauth2.component';

defineLocale('es', esLocale);

@NgModule({
  declarations: [LoginComponent, LoginOauth2Component, ErrorOauth2Component],
  imports: [FormsModule, ReactiveFormsModule, TranslateModule, CommonModule, EffectsModule.forFeature([LoginEffects])]
})
export class LoginModule {}
