import { DropdownItem } from './dropdown-item.model';
import { DropDownSelected } from './dropdown-selected.interface';
import { DropDownInterface } from './dropdown.interface';

export class DropDown implements DropDownInterface {
  constructor(
    public dataItems: Array<DropdownItem> = [],
    public selectedItem?: DropDownSelected,
    public disabled = false
  ) {}
}
