import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { delay, finalize, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import * as _ from 'lodash';
import 'src/app/shared/utils/lodash-mixins';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { languageUtil } from '@shared/utils/languages.util';
import { SpinnerOverlayService } from '@shared/components/spinner-overlay/spinner-overlay.service';

@Injectable()
export class AppHttpInterceptor implements HttpInterceptor {
  private static readonly BLOCKED_PATH = [
    '/alerts',
    // '/terminals/search',
    '/terminals/purchased-quota-search',
    '/terminals/reset-quota',
    '/login/sng/actuator',
    '/report',
    '/report/graph-report',
    '/infrastructure/platform/timezone'
  ];
  private count = 0;

  constructor(private spinnerService: SpinnerOverlayService, private translate: TranslateService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const language =
      languageUtil.find(lan => lan.name === this.translate.currentLang)?.code || this.translate.store.currentLang;
    let requestModify = request.clone({ body: _.removeEmpty(request.body) });
    requestModify = requestModify.clone({
      headers: requestModify.headers.set('Accept-Language', language + ',es;q=0.9,en;q=0.8')
    });
    const path = requestModify.url.replace(environment.backendUrl, '');
    if (AppHttpInterceptor.BLOCKED_PATH.some(blockPath => path.startsWith(blockPath))) {
      return next.handle(requestModify);
    }
    this.spinnerService.show();
    this.count++;
    return next.handle(requestModify).pipe(
      delay(0),
      tap(),
      finalize(() => {
        this.count--;
        if (this.count === 0) {
          this.spinnerService.hide();
        }
      })
    );
  }
}
