import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { TableConfigurationBarComponent } from './table-configuration-bar.component';
import { DropDownModule } from '../dropdown/dropdown.module';

@NgModule({
  declarations: [TableConfigurationBarComponent],
  imports: [TranslateModule, CommonModule, DropDownModule],
  exports: [TableConfigurationBarComponent]
})
export class TableConfigurationBarModule {}
