import { NgModule } from '@angular/core';
import { ComboEditTableComponent } from '@shared/components/combo-edit-table/combo-edit-table.component';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { DropDownTableEditableModule } from '../dropdown-table-editable/dropdown-table-editable.module';
import { ClickOutsideDirectiveModule } from '@shared/directives/ClickOutsideDirective/click-outside-directive.module';
import { ScrollOutsideDirectiveModule } from '@shared/directives/ScrollOutsideDirective/scroll-outside-directive.module';

@NgModule({
  declarations: [ComboEditTableComponent],
  imports: [CommonModule, TranslateModule, FormsModule,DropDownTableEditableModule , ClickOutsideDirectiveModule,
    ScrollOutsideDirectiveModule],
  exports: [ComboEditTableComponent],
  providers: []
})
export class ComboEditTableModule {}
