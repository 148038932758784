<div class="dropdown pba-dropdown w-100">
  <label *ngIf="placeholder" class="col-form-label pba-input-label">{{ placeholder }}</label>
  <button
    class="pba-dropdown-button form-control dropdown-toggle align-items-center"
    type="button"
    data-bs-toggle="dropdown"
    *ngIf="list && list[selected || 0]"
    (click)="onClickButtonDropDown()">
    <i *ngIf="icon !== undefined" class="icon-arrow me-1 d-flex align-self-center icon-{{ icon }}-lg"></i>

    <ng-container *ngIf="list[selected || 0].nameParams; else wintoutParams">
      <span class="b-dropdown">
        {{ list[selected || 0].name | translate : '{params:' + list[selected || 0].nameParams + '}' }}
      </span>
    </ng-container>
    <ng-template #wintoutParams>
      <svg-icon
        *ngIf="list[selected || 0].svgIcon"
        class="me-1"
        name="{{ list[selected || 0].svgIcon?.name }}"
        [svgStyle]="{ 'width.px': 18, 'height.px': 18 }">
      </svg-icon>

      <span *ngIf="!multiple; else textMultiple" class="b-dropdown">
        {{ list[selected || 0].name | translate }}
      </span>
      <ng-template #textMultiple>
        <span class="b-dropdown" data-toggle="tooltip" title="{{ listStr }}">
          {{ listStr }}
        </span>
      </ng-template>
    </ng-template>
  </button>

  <div #dropdownRef (scroll)="onScroll()" class="dropdown-menu p-0" tabindex="0">
    <ul class="menu-selec2 p-0">
      <ng-container *ngFor="let item of list; let i = index; trackBy: trackById">
        <li
          *ngIf="item.show"
          #dropdownItemRef
          class="li-dropdown dropdown-item d-flex"
          id="{{ item.id }}"
          (click)="onClickDropDown(item, $event)"
          translate
          [translateParams]="{ params: item.nameParams }"
          title="{{ item.name }}"
          [ngClass]="{ focus: item.focus }">
          <div *ngIf="multiple" class="pba-check" (click)="onClickDropDown(item, $event)">
            <input
              class="pba-check-input"
              id="{{ 'checkbox' + i + item.id }}"
              type="checkbox"
              [checked]="item.selected" />
            <label class="pba-check-text" for="{{ 'checkbox' + i + item.id }}"></label>
            <label for="{{ 'checkbox' + i + item.id }}" class="pba-check-mark"></label>
          </div>
          <svg-icon
            *ngIf="item.svgIcon"
            class="me-1"
            name="{{ item.svgIcon.name }}"
            [svgStyle]="{
              'width.px': item?.svgIcon?.width || 28,
              'height.px': item?.svgIcon?.height,
              fill: item?.svgIcon?.fill
            }">
          </svg-icon>
          {{ item.name }}
        </li>
      </ng-container>
    </ul>
  </div>

  <span
    *ngIf="showButtonClearFilters"
    (click)="clearfiltersEmit()"
    class="button-clear-filters"
    style="margin-top: 8px">
    x
  </span>
</div>
