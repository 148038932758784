export enum ConfigModules {
  ALERT = 'MODULE_ALERT',
  BEAM = 'MODULE_BEAM',
  BILLING = 'MODULE_BILLING',
  BULKSACTIONS = 'MODULE_BULKACTIONS',
  CLIENT = 'MODULE_CLIENT',
  DOCUMENTATIONS = 'MODULE_DOCUMENTATION',
  HISTORYIP = 'MODULE_HISTORYIP',
  PROFILE = 'MODULE_PROFILE',
  PROMOTIONS = 'MODULE_PROMOTIONS',
  PUBLICIP = 'MODULE_PUBLICIP',
  REPORTING = 'MODULE_REPORTING',
  SLOT = 'MODULE_SLOT',
  TERMINAL = 'MODULE_TERMINAL',
  TERMINALDELETE = 'MODULE_TERMINAL_DELETE',
  TERMINALINVENTORING = 'MODULE_TERMINAL_INVENTORING',
  TERMINALTYPE = 'MODULE_TERMINALTYPE',
  USERS = 'MODULE_USERS'
}
