import { DropDown } from '@shared/components/dropdown/interfaces/dropdown.model';
import { DropdownItem } from '@shared/components/dropdown/interfaces/dropdown-item.model';

export const searchModifiersUtil = [
  {
    id: '3',
    name: 'COMPONENT.TABLE.CONTAINS',
    code: 'CONTAINS',
    svgIcon: 'contains'
  },
  {
    id: '1',
    name: 'COMPONENT.TABLE.STARTWHITH',
    code: 'STARTWHITH',
    svgIcon: 'start-with'
  },
  {
    id: '2',
    name: 'COMPONENT.TABLE.ENDWHITH',
    code: 'ENDWHITH',
    svgIcon: 'end-with'
  },
  {
    id: '4',
    name: 'COMPONENT.TABLE.EQUALS',
    code: 'EQUALS',
    svgIcon: 'equals'
  }
];

export const createComboOfSearchModifier = (): DropDown => {
  const optionsSearchModifier: DropDown = new DropDown();
  optionsSearchModifier.dataItems = searchModifiersUtil.map((modifier: any) => {
    const dropdownItem: DropdownItem = new DropdownItem(modifier.id, modifier.name);
    dropdownItem.svgIcon = {
      name: modifier.svgIcon,
      width: 18,
      height: 18,
      fill: '#4e5b73'
    };
    return dropdownItem;
  });
  return optionsSearchModifier;
};

export const createComboSearchDate = (): DropDown => {
  const combo = createComboOfSearchModifier();
  combo.dataItems = combo.dataItems.filter(item => item.id !== '3');
  return combo;
};
