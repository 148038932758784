import { Injectable } from '@angular/core';
import { TemplatePortal } from '@angular/cdk/portal';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class PortalService {
  private sourceTemplates$ = new BehaviorSubject<Array<TemplatePortal>>([]);

  pushSourceTemplate(sourceTemplate: TemplatePortal) {
    const lastValue = this.sourceTemplates$.value;
    lastValue.push(sourceTemplate);
    this.sourceTemplates$.next(lastValue);
  }

  getSourceTemplates(): Observable<Array<TemplatePortal>> {
    return this.sourceTemplates$;
  }

  reset() {
    // this.sourceTemplates$.complete();
    this.sourceTemplates$.next([]);
  }
}
