export class DropdownMenuItem {
  /**
   *
   * @param id
   * @param name
   * @param nameParams
   * @param data
   */
  constructor(
    public id: any,
    public name: any,
    public nameParams?: any,
    public data?: any,
    public enable?: any,
    public show = true,
    public focus = false,
    public selected = false
  ) {}
}
