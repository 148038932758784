import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  Renderer2,
  OnInit
} from '@angular/core';
import { DropDownSelected } from '../dropdown-table-editable/interfaces/dropdown-selected.interface';
import { DropdownItem } from '../dropdown-table-editable/interfaces/dropdown-item.model';

@Component({
  selector: 'combo-edit-table',
  templateUrl: './combo-edit-table.component.html',
  styleUrls: ['./combo-edit-table.component.scss']
})
export class ComboEditTableComponent implements OnInit, OnChanges {
  @Input() showInput = false;
  @Input() dataCombo: any;
  @Input() isEditable = false;
  @Input() topEmpty = false;
  @Input() data: any;
  @Input() columnName: any;
  @Output() toggled: EventEmitter<void> = new EventEmitter<void>();
  @Output() save: EventEmitter<void> = new EventEmitter<void>();
  @Output() cancel: EventEmitter<void> = new EventEmitter<void>();

  isEmpty: boolean = false;
  test: any;
  valueCombo: any;
  showMenu: any;

  constructor(private el: ElementRef, private renderer: Renderer2) {}
  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    this;
    changes;
  }

  // ngOnDestroy() {
  //   // Elimina el oyente de eventos cuando el componente se destruya
  //   this.renderer.removeEventListener('document', 'click', this.handleClickOutside);
  // }

  onClickSave(event: Event) {
    this.isEmpty = false;
    let newValue;
    let data: any;
    if (this.data.id2) {
      newValue = { id: this.data.id2, comboId: this.valueCombo.id };
      data = { data: newValue, column: this.columnName, id: this.data.id2 };
    } else {
      newValue = { id: this.data.id, comboId: this.valueCombo.id };
      data = { data: newValue, column: this.columnName, id: this.data.id };
    }

    this.save.emit(data);
    event.stopPropagation();
  }

  onClickDelete(event: Event) {
    this.isEmpty = false;
    this.cancel.emit();
    event.stopPropagation();
  }

  onInputChange(event: any): void {
    if (this.data?.name == '') {
      this.isEmpty = true;
    } else {
      this.isEmpty = false;
    }
  }

  onClickData(ddSelected: DropDownSelected) {
    const ddItem: DropdownItem = ddSelected.item;
    this.valueCombo = ddSelected.item;
  }

  public onClickOutSideHidden(event: any) {
      this.onClickDelete(event);
  }

}
