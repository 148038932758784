import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import * as packagejson from '../../../../package.json';

@Injectable()
export class HeaderInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const infoPackageJson = (packagejson as any).default;
    const modifiedReq = request.clone({
      setHeaders: {
        version: infoPackageJson?.version
      }
    });
    return next.handle(modifiedReq);
  }
}
