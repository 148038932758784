import { SvgIconModel } from '@shared/utils/model/svg-icon.mode';
import { DropdownItemInterface } from './dropdown-item.interface';

export class DropdownItem implements DropdownItemInterface {
  /**
   *
   * @param id
   * @param name
   * @param nameParams
   * @param data
   * @param show
   * @param focus
   * @param selected
   * @param svgIcon
   */
  constructor(
    public id: any,
    public name: any,
    public nameParams?: any,
    public data?: any,
    public show = true,
    public focus = false,
    public selected = false,
    public svgIcon?: SvgIconModel
  ) {}
}
