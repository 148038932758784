import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import * as fromActions from './store/login.actions';
import * as HeadSelector from '../../components/cabecera/store/head.selector';
import { filter, skip, take } from 'rxjs';
import { ErrorService } from '../../handlers/errors/error.service';
import { Error } from '../../handlers/errors/error.interface';
import { HttpStatusCode } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { DomainsEnum } from '../../shared/models/common/domains.enum';
import { AuthenticationService } from '@services/authentication.service/authentication.service';
import { ClientDomainEnum } from '@shared/models/client/client-domain.enum';
import { DomainService } from '@shared/services/domain/domain.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LoginComponent implements OnInit {
  errorCorreo: boolean;
  errorCLave: boolean;
  errorLogin: boolean;
  errorLocked: boolean;
  user: string;
  password: string;
  ispopUpShow;
  domain: string = DomainsEnum.HISPASAT;
  acceso = new FormGroup({
    correo: new FormControl('', [Validators.required, Validators.pattern(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,6}$/)]),
    clave: new FormControl('', [Validators.required])
  });
  currentTemplate: string = 'nms';
  domainAxess = false;
  domainNms = true;

  constructor(
    private store: Store,
    private readonly translate: TranslateService,
    private errorService: ErrorService,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService
  ) {
    this.errorCorreo = false;
    this.errorCLave = false;
    this.user = '';
    this.password = '';
    this.ispopUpShow = false;
    this.errorLogin = false;
    this.errorLocked = false;
    this.checkUrlDomain();
    if (DomainService.getDomain() === DomainsEnum.AXESS) {
      this.router.navigate(['/axess']);
    }
  }

  ngOnInit() {}

  enviar() {
    this.errorCLave = false;
    this.errorCorreo = false;
    this.errorLogin = false;
    if (this.acceso.get('correo')?.status !== 'INVALID' && this.acceso.get('clave')?.status !== 'INVALID') {
      this.user = this.acceso.get('correo')?.value || '';
      this.password = this.acceso.get('clave')?.value || '';

      this.errorService.reset(); // reset error state

      this.store.dispatch(fromActions.loginStart({ email: this.user, password: this.password, domain: this.domain }));

      this.errorLocked = false;
      this.errorLogin = false;
      this.errorService.getError().subscribe((error: Error) => {
        if (error && error.status === HttpStatusCode.Locked) {
          this.errorLocked = true;
        }
        if (error.status === HttpStatusCode.Unauthorized || error.status === HttpStatusCode.Forbidden) {
          this.errorLogin = true;
        }
      });
    } else {
      if (this.acceso.get('correo')?.status === 'INVALID') {
        this.errorCorreo = true;
      }
      if (this.acceso.get('clave')?.status === 'INVALID') {
        this.errorCLave = true;
      }
    }
  }
  checkUrlDomain() {
    if (window.location.href.includes('localhost')) {
      if (!window.location.href.includes('4200')) {
        this.domain = DomainsEnum.AXESS;
        this.currentTemplate = 'axess';
        this.domainAxess = true;
        this.domainNms = false;
      } else {
        this.domain = DomainsEnum.HISPASAT;
        this.currentTemplate = 'nms';
        this.domainAxess = false;
        this.domainNms = true;
      }
    } else if (window.location.href.includes('axess')) {
      this.domain = DomainsEnum.AXESS;
      this.currentTemplate = 'axess';
      this.domainAxess = true;
      this.domainNms = false;
    } else {
   
      this.domain = DomainsEnum.HISPASAT;
      this.currentTemplate = 'nms';
      this.domainAxess = false;
      this.domainNms = true;
    }
  }

  checkUrlAxess() {
    this.domain = DomainsEnum.AXESS;
    this.currentTemplate = 'axess';
    this.domainAxess = true;
    this.domainNms = false;
  }

  checkUrlNms() {
    this.domain = DomainsEnum.HISPASAT;
    this.currentTemplate = 'nms';
    this.domainAxess = false;
    this.domainNms = true;
  }

  setErrors(error: boolean) {
    this.errorCLave = error;
    this.errorCorreo = error;
  }
}
