import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { DropdownItemInterface } from '@shared/components/dropdown/interfaces/dropdown-item.interface';
import { DropdownItem } from '@shared/components/dropdown/interfaces/dropdown-item.model';
import { DropDownSelected } from '@shared/components/dropdown/interfaces/dropdown-selected.interface';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-table-filter-select-unique',
  templateUrl: './table-filter-select-unique.component.html',
  styleUrls: ['./table-filter-select-unique.component.scss']
})
export class TableFilterSelectUniqueComponent implements OnInit, OnChanges, OnDestroy {
  @Input() placeholder?: string;
  @Input() options?: Array<DropdownItemInterface>;
  @Input() optional?: DropdownItemInterface;
  @Input() selected?: number = 0;
  @Input() disabled = false;
  @Input() infinite = false;
  @Input() icon?: string;
  @Input() inputSearch = '';
  @Input() selectOptionUnique?: string;
  @Input() modulo = '';

  @Output() clickPbaDropdown = new EventEmitter<DropDownSelected>();
  @Output() infiniteScroll = new EventEmitter<boolean>();
  @Output() filterEvent = new EventEmitter<boolean>(); //false = down true = up

  multiple = true;
  list = new Array<DropdownItemInterface>();
  listStr = '';
  searchText?: string;
  showButtonClearFilters = false;

  @ViewChild('search', { static: false }) private searchTextRef?: ElementRef;
  @ViewChild('dropdownRef', { static: false }) private dropdownRef!: ElementRef;
  @ViewChildren('dropdownItemRef', { read: ElementRef })
  private dropdownItemRef!: QueryList<ElementRef>;
  @Input() private keepSearchTextClick = false;
  private focusIndex = 0;
  private focusDownIndex = 1;
  private cellSize = 45;
  private cellGroupSize = this.cellSize * 4;
  // private searchInput$ = new Subject<string>();
  private activeInputSearch = false;
  private clearfilters$ = new Subscription();

  constructor(private readonly translate: TranslateService, private store: Store) {}

  ngOnInit() {
    this.initDataDropDown();
    this.initFocus();

    this.clearfilters$ = this.store
      .select((state: any) => state.shared.table[this.modulo])
      .subscribe(modulo => {
        if (modulo && modulo.length <= 0) {
          this.clearfilters();
        }
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.initDataDropDown();
    this.initFocus();

    if (
      changes['selectOptionUnique'] &&
      changes['selectOptionUnique'].currentValue != undefined &&
      this.showButtonClearFilters
    ) {
      this.filterEvent.emit(true);
    }
  }

  ngOnDestroy() {
    if (this.clearfilters$) this.clearfilters$.unsubscribe();
  }

  onClickDropDown(itemSelected: DropdownItemInterface, event?: any) {
    const numero = this.list.filter(s => s.selected === true).length;

    this.executeUniqueSelection(itemSelected, event);

    this.showButtonClearFilters = this.list.some(s => s.selected === true);

    if (numero == 0 && this.showButtonClearFilters) {
      this.filterEvent.emit(true);
    }

    if (!this.showButtonClearFilters) {
      this.filterEvent.emit(false);
    }
  }

  onChangeTextSearch(searchText: string) {
    this.filterStatusNode(searchText);
    this.initFocus();
    this.dropdownRef.nativeElement.scrollTop = 0;
    this.checkShowOptionalMultiple();
  }

  onClickButtonDropDown() {
    this.searchTextRef?.nativeElement.focus();
    if (!this.multiple) {
      if (this.keepSearchTextClick) {
        this.onClickButtonDropDownWithKeepSearchText();
      } else {
        this.onClickButtonDropDownNotKeepSearchText();
      }
    }
  }

  onKeyUpSelected(event: any) {
    event.preventDefault();
    // Simulamos click debido a que bootstrap al hacer filtrado en el input y no se entera del nuevo dom.
    const elements: Array<ElementRef> = this.dropdownItemRef['_results'].filter(
      (ref: any) => ref.nativeElement.style.display !== 'none'
    );
    elements[this.focusIndex].nativeElement.click();
  }

  onKeyDownArrowDown() {
    if (this.focusIndex < this.getListWithNodeShow().length - 1) {
      this.changeKeyDownArrowDownFocus();
      this.changeKeyDownArrowDownScroll();
    }
  }

  onKeyDownArrowUp() {
    if (this.focusIndex !== 0) {
      this.changeKeyDownArrowUpFocus();
      this.changeKeyDownArrowUpScroll();
    }
  }

  onScroll() {
    if (this.infinite) {
      const scrollTop = this.dropdownRef.nativeElement.scrollTop;
      const clientHeight = this.dropdownRef.nativeElement.clientHeight;
      const scrollHeight = this.dropdownRef.nativeElement.scrollHeight;
      if (scrollTop + clientHeight + 1 >= scrollHeight) {
        this.infiniteScroll.emit();
      }
    }
  }

  onBlurInputSearch() {
    this.activeInputSearch = false;
    this.dropdownRef.nativeElement.focus();
  }

  onFocusInputSearch() {
    this.activeInputSearch = true;
  }

  trackById = (i: number, e: any) => e.id;

  private initDataDropDown() {
    this.optional = {
      id: -1,
      name: '',
      show: false,
      focus: false,
      selected: false
    };

    if (this.selected === undefined) {
      this.selected = 0;
    }

    if (this.options) {
      this.list = this.options.slice();

      this.list.forEach(node => (node.show = true));
      if (this.optional) {
        this.list.unshift(this.optional);
      }

      if (this.list[this.selected] === undefined || this.list[this.selected] === null) {
        this.selected = 0;
      }

      if (!this.multiple && this.options.some(op => op.selected)) {
        const opSelected: Array<DropdownItemInterface> = this.options.filter(op => op.selected);
        opSelected.forEach(s => {
          s.selected = false;
          this.selected = this.list.findIndex(o => o.id === s.id);
        });
      }

      if (this.selectOptionUnique && this.list) {
        const optSelect = this.list.find((f: any) => f.id == this.selectOptionUnique);
        if (optSelect) optSelect.selected = true;
      }

      this.listStr = this.list
        .filter(node => node.selected && node.id !== this.optional?.id)
        .map(c => this.translate.instant(c.name))
        .join(', ');

      this.showButtonClearFilters = this.listStr != '';
    }
  }

  private checkShowOptionalMultiple() {
    if (this.multiple && this.optional && this.searchText) {
      this.optional.show = !this.searchText;
    }
  }

  private filterStatusNode(searchText: string) {
    this.list.forEach(node => {
      node.show = !(!node.name || !this.translate.instant(node.name).toLowerCase().includes(searchText.toLowerCase()));
      if (this.multiple && node.selected) {
        node.show = true;
      }
    });
  }

  private initFocus() {
    if (this.list && this.getListWithNodeShow().length) {
      this.focusIndex = 0;
      this.focusDownIndex = 1;
      this.list.forEach(op => (op.focus = false));
      this.getListWithNodeShow()[this.focusIndex].focus = true;
    }
  }

  private checkIsFocusActive() {
    if (!this.list.some(node => (node.show = true))) {
      this.focusIndex = 0;
      this.getListWithNodeShow()[this.focusIndex].focus = true;
    }
  }

  private onClickButtonDropDownWithKeepSearchText() {
    setTimeout(() => {
      if (this.searchTextRef) {
        this.searchTextRef.nativeElement.focus();
      }
      const elements: ElementRef[] = this.dropdownItemRef['_results'].filter(
        (ref: any) => ref.nativeElement.style.display !== 'none'
      );
      let index = elements.findIndex(element => +element.nativeElement.id === this.list[this.selected || 0].id);
      if (index === -1) {
        index = 0;
      }
      this.focusIndex = index;
      this.list.forEach(op => (op.focus = false));
      this.getListWithNodeShow()[this.focusIndex].focus = true;
      this.focusDownIndex = 1;
      if (this.focusIndex > 4) {
        this.focusDownIndex = this.focusIndex - 3;
      }
      this.changeKeyDownArrowDownScroll();
    }, 0);
  }

  private onClickButtonDropDownNotKeepSearchText() {
    if (!this.infinite) {
      setTimeout(() => {
        if (this.searchTextRef) {
          this.searchTextRef.nativeElement.focus();
        }
        if (this.searchText) this.searchText = '';
        this.initDataDropDown();
        this.list.forEach(node => {
          node.focus = false;
          node.show = true;
        });
        this.dropdownItemRef['_results'].forEach((ref: any) => (ref.nativeElement.style.display = 'flex'));
        let index = this.dropdownItemRef['_results'].findIndex(
          (element: any) => +element.nativeElement.id === this.list[this.selected || 0].id
        );
        if (index === -1) {
          index = 0;
        }
        this.focusIndex = index;
        this.list[this.focusIndex].focus = true;
        this.focusDownIndex = 1;
        if (this.focusIndex > 4) {
          this.focusDownIndex = this.focusIndex - 3;
        }
        this.changeKeyDownArrowDownScroll();
      }, 0);
    }
  }

  private changeKeyDownArrowDownFocus() {
    this.getListWithNodeShow()[this.focusIndex].focus = false;
    this.focusIndex++;
    this.getListWithNodeShow()[this.focusIndex].focus = true;
  }

  private changeKeyDownArrowDownScroll() {
    const topPos = this.getTopPosDropDownShow();
    const pos = this.focusDownIndex * this.cellSize + 4 * this.cellSize;
    if (topPos >= pos) {
      this.dropdownRef.nativeElement.scrollTop = topPos - this.cellGroupSize;
      this.focusDownIndex++;
    }
  }

  private changeKeyDownArrowUpFocus() {
    this.getListWithNodeShow()[this.focusIndex].focus = false;
    this.focusIndex--;
    this.getListWithNodeShow()[this.focusIndex].focus = true;
  }

  private changeKeyDownArrowUpScroll() {
    const topPos = this.getTopPosDropDownShow();
    if (this.focusDownIndex * this.cellSize > topPos) {
      this.dropdownRef.nativeElement.scrollTop = topPos - this.cellSize;
      this.focusDownIndex--;
    }
  }

  private getTopPosDropDownShow() {
    const elements = this.dropdownItemRef['_results'].filter((ref: any) => ref.nativeElement.style.display !== 'none');
    const topPos = elements[this.focusIndex] ? elements[this.focusIndex].nativeElement.offsetTop : 0;
    return topPos;
  }

  private getListWithNodeShow() {
    return this.list.filter(node => node.show === undefined || node.show);
  }

  private executeHeadSearch(lastKeyupParams: string) {
    if (lastKeyupParams) {
      this.inputSearch = lastKeyupParams;
    }
    const item: DropdownItem = this.list[this.selected || 0] as DropdownItem;
    this.clickPbaDropdown.emit({
      index: this.selected || 0,
      item: item,
      selected: item.selected,
      searchText: this.inputSearch
    });

    if (!this.showButtonClearFilters && this.inputSearch && this.inputSearch !== '') {
      this.showButtonClearFilters = true;
      this.filterEvent.emit(true);
    }

    if (!this.showButtonClearFilters) {
      this.filterEvent.emit(false);
    }

    this.showButtonClearFilters = this.inputSearch.trim() !== '';
  }

  private onClickCheckMultiple(item: DropdownItemInterface, event?: any) {
    item.selected = !item.selected;
    this.activeInputSearch = false;
    if (!item.selected) {
      this.filterStatusNode(this.searchText || '');
      this.checkIsFocusActive();
    }
    this.checkNodeSelectAllMultiple(item);
    this.checkShowOptionalMultiple();
    event.stopPropagation();
    event.preventDefault();

    this.listStr = this.list
      .filter(node => node.selected && node.id !== this.optional?.id)
      .map(c => this.translate.instant(c.name))
      .join(', ');
  }

  private executeUniqueSelection(itemSelected: DropdownItemInterface, event: any) {
    itemSelected.selected = !itemSelected.selected;
    this.listStr = '';
    if (itemSelected.selected) {
      this.list.forEach(node => {
        node.selected = itemSelected.id === node.id;
      });
      this.listStr = this.translate.instant(itemSelected.name);
    }
    this.list.forEach(node => (node.show = node.id !== -1));
    event.stopPropagation();
    event.preventDefault();

    const selectItem = itemSelected.selected ? itemSelected : this.list[0];

    this.clickPbaDropdown.emit({
      index: this.list.findIndex(node => node.id === selectItem.id),
      item: selectItem as DropdownItem,
      selected: selectItem.selected || false,
      searchText: selectItem.name
    });
  }

  private checkNodeSelectAllMultiple(item: DropdownItemInterface) {
    if (this.optional) {
      if (item.id === this.optional.id) {
        this.list.forEach(node => {
          node.selected = item.selected;
          node.show = true;
          if (this.searchText) this.searchText = '';
        });
      } else {
        this.optional.selected = this.list.every(node => node.selected || node.id === this.optional?.id);
      }
    }
  }

  clearfilters() {
    this.showButtonClearFilters = false;
    if (this.listStr) this.listStr = '';
    if (this.searchText) this.searchText = '';

    this.list.forEach(node => {
      node.selected = false;
      node.show = node.id !== -1;
    });
  }

  clearfiltersEmit() {
    this.clearfilters();

    this.clickPbaDropdown.emit({
      index: 0,
      item: new DropdownItem(0, ''),
      selected: false,
      searchText: ''
    });

    this.filterEvent.emit(false);
  }
}
