<div style="position: absolute; width: 11%" *ngIf="showInput && isEditable">
  <div style="width: 100%">
    <app-dropdown-table-editable
      [options]="dataCombo"
      class="pba-dropdown-table"
      [optional]="{ id: -1, name: 'COMMONS.SELECT' }"
      [topEmpty]="topEmpty"
      (clickPbaDropdown)="onClickData($event)"
      appClickOutside
      discardedClass="icon-edit-table-lg"
      (clickOutside)="onClickOutSideHidden($event)">
    </app-dropdown-table-editable>
  </div>

  <div>
    <div
      *ngIf="showInput && isEditable"
      class="button-clear-filters"
      (click)="onClickDelete($event)"
      [ngClass]="{ topEmpty: topEmpty }">
      X
    </div>

    <div
      *ngIf="showInput && isEditable && !isEmpty"
      class="button-clear-filters2"
      (click)="onClickSave($event)"
      [ngClass]="{ topEmpty: topEmpty }">
      <i class="icon-check-lg icon-list-edit icon-map"></i>
    </div>
  </div>
</div>
