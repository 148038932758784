import { HttpClient } from '@angular/common/http';
import { Injectable, Optional } from '@angular/core';
import { SvgIconRegistryService } from 'angular-svg-icon';
import { take } from 'rxjs/operators';
import { CustomIconData, IconFilesData, IconImageFile } from './models/icon.interface';
import { AngularSvgIconPreloaderConfig } from './models/angular-svg-icon-preloader-config.class';

@Injectable({
  providedIn: 'root'
})
export class AngularSvgIconPreloaderService {
  private configUrl = './assets/icons.json';
  private iconsFileData!: IconFilesData;

  constructor(
    private _http: HttpClient,
    @Optional() config: AngularSvgIconPreloaderConfig,
    private _iconRegistry: SvgIconRegistryService
  ) {
    if (config && config.configUrl) {
      this.configUrl = config.configUrl;
    }
  }

  loadConfig(): Promise<any> {
    return this._http
      .get(this.configUrl)
      .toPromise()
      .then(configData => {
        this.iconsFileData = configData as IconFilesData;
        this.loadIcons();
      })
      .catch((err: any) => {
        console.error('An error occurred loading the icons:\n', err, '\nNo icons will be loaded.');
        this.iconsFileData = { customIcons: [], iconImageFiles: [] };
        this.loadIcons();
      });
  }

  private loadIcons() {
    this.iconsFileData.iconImageFiles.forEach((i: IconImageFile) => {
      this._iconRegistry
        .loadSvg(i.iconPath, i.iconName)
        ?.pipe(take(1))
        .subscribe(() => {
          // do nothing
        });
    });
    this.iconsFileData.customIcons.forEach((i: CustomIconData) => {
      this._iconRegistry.addSvg(i.iconName, i.iconData);
    });
  }
}
