<div class="dropdown pba-dropdown w-100 d-flex pba-input-block">
  <ng-container>
    <div (click)="onClickButtonDropDown()" data-bs-toggle="dropdown" class="pba-input-block__icons">
      <svg-icon
        *ngIf="list[selected || 0].svgIcon"
        class="me-1 pba-input-block__icon-selected d-flex"
        name="{{ list[selected || 0].svgIcon?.name }}"
        [svgStyle]="{ 'width.px': 28, 'height.px': 18 }">
      </svg-icon>

      <div class="pba-input-block__icon">
        <i class="pba-input-block__icon--pos"></i>
      </div>
    </div>

    <input
      type="text"
      class="pba-input-text pba-input-block__text form-control"
      #keyupHeadDD
      [(ngModel)]="inputSearch"
      (input)="onKeyHeadSearch(keyupHeadDD.value)" />
  </ng-container>

  <div #dropdownRef class="dropdown-menu p-0" tabindex="0">
    <div *ngIf="searchBox" class="search-select2">
      <input
        type="text"
        #search
        class="pba-input-search m-1 w-100"
        [(ngModel)]="searchText"
        (ngModelChange)="onChangeTextSearch()"
        (keydown.arrowdown)="onKeyDownArrowDown()"
        (keydown.arrowup)="onKeyDownArrowUp()"
        (keydown.enter)="onKeyUpSelected($event)" />
    </div>
    <ul class="menu-selec2 p-0">
      <ng-container *ngFor="let item of list; let i = index; trackBy: trackById">
        <li
          *ngIf="item.show"
          #dropdownItemRef
          class="li-dropdown dropdown-item d-flex"
          id="{{ item.id }}"
          (click)="onClickDropDown(item, $event, i)"
          translate
          [translateParams]="{ params: item.nameParams }"
          title="{{ item.name }}"
          [ngClass]="{ focus: item.focus }">
          <svg-icon
            *ngIf="item.svgIcon"
            class="me-1"
            name="{{ item.svgIcon.name }}"
            [svgStyle]="{
              'width.px': item?.svgIcon?.width || 28,
              'height.px': item?.svgIcon?.height,
              fill: item?.svgIcon?.fill
            }">
          </svg-icon>
          {{ item.name }}
        </li>
      </ng-container>
    </ul>
  </div>

  <div
    *ngIf="showButtonClearFilters"
    (click)="clearfiltersEmit()"
    class="button-clear-filters"
    style="margin-top: 16px">
    x
  </div>
</div>
