import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { SpinnerOverlayComponent } from './spinner-overlay.component';

@Injectable({
  providedIn: 'root'
})
export class SpinnerOverlayService {
  private overlayRef!: OverlayRef;
  private showed = false;

  constructor(private overlay: Overlay) {}

  public show() {
    // Returns an OverlayRef (which is a PortalHost)
    if (!this.showed) {
      this.showed = true;
      if (!this.overlayRef) {
        this.overlayRef = this.overlay.create();
      }
      // Create ComponentPortal that can be attached to a PortalHost
      const spinnerOverlayPortal = new ComponentPortal(SpinnerOverlayComponent);
      this.overlayRef.attach(spinnerOverlayPortal); // Attach ComponentPortal to PortalHost
    }
  }

  public hide() {
    if (!!this.overlayRef && this.showed) {
      this.overlayRef.detach();
      this.showed = false;
    }
  }
}
