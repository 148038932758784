import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { map } from 'rxjs';
import { DataBaseDto } from '@shared/models/data-base/data-base-dto.model';
import { BaseDto } from '@shared/models/data-base/base-dto.model';

@Injectable({
  providedIn: 'root'
})
export class DataLanguageService {
  constructor(private httpClient: HttpClient) {}

  getLanguages() {
    return this.httpClient
      .get<any>(environment.backendUrl + '/users/languages', {
        observe: 'body',
        responseType: 'json'
      })
      .pipe(map((baseDto: DataBaseDto<Array<BaseDto>>) => baseDto.data));
  }

  updateLanguage(code: string) {
    return this.httpClient.put<any>(
      environment.backendUrl + '/users/language',
      { code: code },
      {
        observe: 'body',
        responseType: 'json'
      }
    );
  }
}
