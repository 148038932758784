import { createAction, props } from '@ngrx/store';
import { User } from '../../../shared/models/users/user.model';

export enum LoginActionTypes {
  loginStart = '[LOGIN] REQUEST LOGIN START',
  loginStartSuccess = '[LOGIN] REQUEST LOGIN START SUCCESS',
  tokenAzure = '[LOGIN] REQUEST TOKEN AZURE',
  tokenAzureError = '[LOGIN] ERROR TOKEN AZURE'
}

export const loginStart = createAction(
  LoginActionTypes.loginStart,
  props<{ email: string; password: string; domain: string }>()
);

export const loginStartSuccess = createAction(LoginActionTypes.loginStartSuccess, props<{ user: User }>());

export const tokenAzureStart = createAction(LoginActionTypes.tokenAzure);

export const tokenAzureError = createAction(LoginActionTypes.tokenAzureError, props<{ error: any }>());
