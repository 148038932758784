import { AfterViewInit, Component, EventEmitter, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-modal-service-from-component',
  templateUrl: './modal-service-from-component.component.html',
  styleUrls: ['./modal-service-from-component.component.scss']
})
export class ModalServiceFromComponentComponent implements AfterViewInit{
  @Output() accept = new EventEmitter<any>();
  @Output() onCloseSubject = new EventEmitter<any>();

  list: any;
  showConfirm = false;
  confirmText = '';

  constructor(public bsModalRef: BsModalRef) {

  }
  ngAfterViewInit(): void {

    if(this.list && this.list[0] && this.list[0] == 'Su empresa no dispone de privilegios para acceder, por favor, póngase en contacto con contact@hispasat.com'){
      document.body.style.backgroundImage ="url('/assets/logos/login_axess.jpg')";
      document.body.style.backgroundSize = "cover"; // Ajusta la imagen para que se ajuste dentro del área del elemento
      document.body.style.backgroundPosition = "center"; // Centra la imagen en el fondo
      document.body.style.backgroundRepeat = "no-repeat";
      document.body.style.backgroundAttachment = "fixed";
    }

  }

  confirm(): void {
    this.onAccept();
    this.bsModalRef.hide();
  }

  decline(): void {
    this.bsModalRef.hide();
  }

  public onAccept() {
    return this.accept.emit('foo');
  }

  onClickOutside(event: any) {
    this.onCloseSubject.emit();
  }
}
