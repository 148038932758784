<div
  #dropdownMenuRef
  appClickOutside
  (clickOutside)="documentClickListener()"
  class="dropdown pba-dropdown position-static">
  <div *ngIf="showMenu" class="dropdown-menu d-block">
    <ul class="ul-adjust menu-selec2 p-0">
      <li
        *ngFor="let item of list; let i = index; trackBy: trackByIndex"
        [hidden]="!item.show"
        [ngClass]="{ 'disable-options': disableOptions && !item.enable }"
        class="li-dropdown dropdown-item"
        id="{{ item.id }}"
        (click)="onClickDropDownMenu(item, i)"
        translate
        [translateParams]="{ params: item.nameParams }"
        title="{{ item.name }}"
        data-toggle="tooltip"
        title="{{ item.name | translate }}">
        <i class="icon-{{ item.id }}-md padding-icon"></i> {{ item.name }}
      </li>
    </ul>
  </div>
</div>
