import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-modal-add-favorite',
  templateUrl: './modal-add-favorite.component.html',
  styleUrls: ['./modal-add-favorite.component.scss']
})
export class ModalAddFavoriteComponent implements OnInit, AfterViewInit, OnDestroy {
  public onCloseSubject$: Subject<unknown | undefined> = new Subject();

  modalRef!: BsModalRef;
  favoriteName = '';
  enabledSave = false;

  @ViewChild('favoriteInput', { static: false }) favoriteInput: any;

  constructor(public bsModalRef: BsModalRef, private readonly translate: TranslateService) {
    this.translate.use(this.translate.store.currentLang);
    this.translate.store.onLangChange.subscribe(lang => this.translate.use(lang.lang));
  }

  ngOnInit(): void {
    this.onCloseSubject$ = new Subject();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.favoriteInput.nativeElement.focus();
    }, 0);
  }

  ngOnDestroy() {
    if (this.bsModalRef) this.bsModalRef.hide();
  }

  onChangeText() {
    this.enabledSave = this.favoriteName.trim() !== '';
  }

  onConfirm() {
    if (this.enabledSave) {
      this.onCloseSubject$.next({ favoriteName: this.favoriteName, query: '' });
    }
    if (this.bsModalRef) this.bsModalRef.hide();
  }
}
