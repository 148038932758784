import { Action, createReducer, on } from '@ngrx/store';
import { TerminalInfo, TerminalWifiState } from '../model';
import {
  terminalWifiChangePasswordPutSuccess,
  terminalWifiDeviceLoadSuccess,
  terminalWifiInfoLoadSuccess,
  terminalWifiNetworkChangeStatusSuccess,
  terminalWifiNetworkLoadSuccess,
  terminalWifiResetValuesPutSuccess,
  terminalWifiStoreClear
} from './terminal-wifi.actions';

const initialState: TerminalWifiState = {
  info: {} as TerminalInfo,
  network: [],
  devices: [],
  changePassword: false,
  resetValues: false
};

function terminalWifiInfoSuccess(state: any, action: any) {
  return { ...state, info: { ...action.info } };
}

function terminalWifiNetworkChangeStatus(state: any, action: any) {
  const cloneNetwork = state.network.map((network: any) => ({ ...network }));

  const updatedNetwork = cloneNetwork.map((network: any) => {
    if (network.network === action.network) {
      return {
        ...network,
        status: action.status
      };
    } else {
      return network;
    }
  });

  return {
    ...state,
    network: updatedNetwork
  };
}

function terminalWifiNetworkSuccess(state: any, action: any) {
  return { ...state, network: [...action.network] };
}

function terminalWifiDeviceSuccess(state: any, action: any) {
  return { ...state, devices: [...action.devices] };
}

function terminalWifiResetValuesSuccess(state: any, action: any) {
  return { ...state, resetValues: action.resetValues };
}

function terminalWifiChangePasswordSuccess(state: any, action: any) {
  return { ...state, changePassword: action.changePassword };
}

function terminalWifiInitialState() {
  return initialState;
}

const terminalWifiReducer = createReducer(
  initialState,
  on(terminalWifiStoreClear, terminalWifiInitialState),
  on(terminalWifiInfoLoadSuccess, terminalWifiInfoSuccess),
  on(terminalWifiNetworkChangeStatusSuccess, terminalWifiNetworkChangeStatus),
  on(terminalWifiNetworkLoadSuccess, terminalWifiNetworkSuccess),
  on(terminalWifiDeviceLoadSuccess, terminalWifiDeviceSuccess),
  on(terminalWifiResetValuesPutSuccess, terminalWifiResetValuesSuccess),
  on(terminalWifiChangePasswordPutSuccess, terminalWifiChangePasswordSuccess)
);

export function reducer(state: any, action: Action) {
  return terminalWifiReducer(state, action);
}
