import { AfterViewInit, Component, Input, ViewChild } from '@angular/core';
import { PortalService } from '@shared/components/portal/portal.service';
import { TemplatePortal } from '@angular/cdk/portal';

@Component({
  selector: 'app-portal',
  templateUrl: './portal.component.html'
})
export class PortalComponent implements AfterViewInit {
  @Input() reset = false;
  @ViewChild('portalTemplate') private contentTemplate!: TemplatePortal;

  constructor(private portalService: PortalService) {}

  ngAfterViewInit() {
    if (this.reset) {
      this.portalService.reset();
    }
    this.portalService.pushSourceTemplate(this.contentTemplate);
  }
}
