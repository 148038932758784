import { Injectable } from '@angular/core';
import { HttpEvent, HttpRequest, HttpInterceptor, HttpHandler, HttpResponse } from '@angular/common/http';
import { Observable, of, tap } from 'rxjs';
import { environment } from '@environments/environment';

@Injectable()
export class CacheInterceptor implements HttpInterceptor {
  private static readonly ENABLED_PATH = [
    // '/graphdata/graph/slot/trafficfwdrtn?', // grafico de trafico de slot
    // '/graphdata/graph/subSlot//trafficfwdrtn?', // grafico de trafico de beam
    // '/graphdata/graph/terminal/trafficfwdrtn?', // grafico de trafico de terminales
    '/infrastructure/platform/timezone'
  ];

  constructor(private httpRequestCache: HttpRequestCache) {}

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.method !== 'GET') {
      return next.handle(request);
    }

    const path = request.url.replace(environment.backendUrl, '');
    if (!CacheInterceptor.ENABLED_PATH.some(enabledPath => path.startsWith(enabledPath))) {
      return next.handle(request);
    }

    const cachedResponse: HttpResponse<any> = this.httpRequestCache.get(request.url);

    if (cachedResponse) {
      return of(cachedResponse.clone());
    } else {
      return next.handle(request).pipe(
        tap((response: any) => {
          if (response instanceof HttpResponse) this.httpRequestCache.set(request.url, response);
        })
      );
    }
  }
}

@Injectable({ providedIn: 'root' })
export class HttpRequestCache {
  private cache: Map<string, any> = new Map();

  public get(key: string): HttpResponse<any> {
    return this.cache.get(key);
  }

  public set(key: string, value: any): void {
    this.cache.set(key, value);
  }

  public clear() {
    this.cache.clear();
  }
}
