import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '@services/authentication.service/authentication.service';
import { ModalConfirmComponent } from '@shared/components/modal/modal-confirm/modal-confirm.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-error-oauth2',
  templateUrl: './error-oauth2.component.html',
  styleUrls: ['./error-oauth2.component.scss']
})
export class ErrorOauth2Component implements OnInit {
  private modalRef!: BsModalRef;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private modalService: BsModalService,
    protected authService: AuthenticationService
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      const errorType = params['error'];
      let errorMessage = 'LOGIN.ERROR.OAUTH2.DEFAULT';
      if (errorType === 'user') {
        errorMessage = 'LOGIN.ERROR.OAUTH2.USER';
      } else if (errorType === 'domain') {
        errorMessage = 'LOGIN.ERROR.OAUTH2.DOMAIN';
      }

      const initialState: any = {};
      initialState['textToShow'] = errorMessage;
      initialState['showButtons'] = false;
      this.modalRef = this.modalService.show(ModalConfirmComponent, {
        initialState,
        class: 'modal-lg'
      });

      this.modalRef.content.onCloseSubject.subscribe(() => {
        this.authService.logout();
      });
    });
  }
}
