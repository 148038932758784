import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { AuthenticationService } from '../services/authentication.service/authentication.service';
import { ModalServiceFromComponentComponent } from '../shared/components/modal/modal-service-from-component/modal-service-from-component.component';
import { TranslateService } from '@ngx-translate/core';
import { ClientDomainEnum } from '@shared/models/client/client-domain.enum';
import { DomainService } from '@shared/services/domain/domain.service';
import { DomainsEnum } from '@shared/models/common/domains.enum';
// import { RoleEnum } from '@shared/models/users/role.enum';

@Injectable({ providedIn: 'root' })
export class AuthLoginGuardService implements CanActivate {
  bsModalRef!: BsModalRef;

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private modalService: BsModalService,
    private translate: TranslateService
  ) {}

  openModalNotPermission() {
    const initialState = {
      list: this.translate.instant('USERS.SESSION.PERMISSION'),
      title: ''
    };
    this.bsModalRef = this.modalService.show(ModalServiceFromComponentComponent, { initialState });
    this.bsModalRef.content.closeBtnName = 'Close';
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser = this.authenticationService.currentUser.value;
    if (currentUser) {
      if (route.data && route.data['minRole']) {
        const minRole = route.data['minRole'];
        const isValidUserRole = this.authenticationService.checkUserRole(minRole);
        const isValidClientTypeRole = this.authenticationService.checkClientTypeRole(minRole);
        const isValid =  (isValidUserRole || isValidClientTypeRole);

        if(isValid) return true;

      }

      if (state.url !== '/dashboard') {
        this.openModalNotPermission();
      }
    }

    if (DomainService.getDomain() === DomainsEnum.HISPASAT) {
      this.router.navigate(['/login']);
    } else if (DomainService.getDomain() === DomainsEnum.AXESS) {
      this.router.navigate(['/axess']);
    } else {
      this.router.navigate(['/login']);
    }
    return false;
  }
}
