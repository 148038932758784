<div class="justify-modal-center global-slot-share">
  <div class="modal-header bd-example-modal-lg">
    <h5>{{ 'COMPONENT.TABLE.SHARE_SEARCH.MODAL.TITLE' | translate }}</h5>
    <button
      type="button"
      class="btn-close"
      data-bs-dismiss="modal"
      aria-label="Close"
      (click)="bsModalRef.hide()"></button>
  </div>
  <div class="modal-body">
    <div class="col-12" style="margin: 24px auto">
      <div class="url-text" [ngClass]="{ 'url-text-copy': hasUrlCopy }">
        <span *ngIf="hasUrl">{{ url }}</span>

        <app-spinner *ngIf="!hasUrl" [fullscreen]="true"></app-spinner>
      </div>
    </div>

    <div class="modal-footer">
      <div style="display: flex">
        <button *ngIf="hasUrl" type="button" class="btn btn-primary me-2" (click)="clipboardCopy()">
          {{ 'COMPONENT.TABLE.SHARE_SEARCH.MODAL.BUTTON_COPY' | translate }}
        </button>

        <button type="button" class="btn btn-outline" (click)="bsModalRef.hide()">
          {{ 'COMMONS.CLOSE' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
