import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Error } from './error.interface';

@Injectable({ providedIn: 'root' })
export class ErrorService {
  private error$ = new BehaviorSubject<Error>({ status: 0 });

  setError(error: Error) {
    this.error$.next(error);
  }

  getError(): BehaviorSubject<Error> {
    return this.error$;
  }

  getErrorStatus(): number {
    return this.error$.value.status;
  }

  reset() {
    this.setError({ status: 0 });
  }
}
