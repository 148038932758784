import { Injectable } from '@angular/core';
import { DomainsEnum } from '../../models/common/domains.enum';

@Injectable({
  providedIn: 'root'
})
export class DomainService {
  static getDomain() {
    const href = window.location.href;
    const isLocal = this.isLocalHost(href);
    const isAXESS = this.isAXESSDomain(href);

    if (isLocal && !href.includes('4200')) {
      return DomainsEnum.AXESS;
    }

    if (!isLocal && isAXESS) {
      return DomainsEnum.AXESS;
    }
    return DomainsEnum.HISPASAT;
  }

  static isLocalHost(href: string = window.location.href): boolean {
    return href.includes('localhost');
  }

  static isAXESSDomain(href: string = window.location.href): boolean {
    return href.includes('axess');
  }
}

// export class DomainService {
//   static getDomain() {
//     let domain = DomainsEnum.HISPASAT;
//     if (window.location.href.includes('localhost')) {
//       if (!window.location.href.includes('4200')) {
//         domain = DomainsEnum.AXESS;
//       }
//     } else if (!window.location.href.includes('nms')) {
//       domain = DomainsEnum.AXESS;
//     }

//     return domain;
//   }

//   static isLocalHost(): boolean {
//     return window.location.href.includes('localhost');
//   }
// }
