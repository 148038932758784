import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { Store } from '@ngrx/store';
import { DropDownSelected } from '@shared/components/dropdown/interfaces/dropdown-selected.interface';
import { Subject, Subscription, debounceTime } from 'rxjs';
import { DropdownItem } from '@shared/components/dropdown/interfaces/dropdown-item.model';
import { DropdownItemInterface } from '@shared/components/dropdown/interfaces/dropdown-item.interface';
import { TranslateService } from '@ngx-translate/core';
import _ from 'lodash';

@Component({
  selector: 'app-table-filter-input',
  templateUrl: './table-filter-input.component.html',
  styleUrls: ['./table-filter-input.component.scss']
})
export class TableFilterInputComponent implements OnInit, OnChanges, OnDestroy {
  @Input() placeholder?: string;
  @Input() options?: any;
  @Input() selected?: number = 0;
  @Input() disabled = false;
  @Input() icon?: string;
  @Input() private keepSearchTextClick = false;
  @Input() selectedItem: any;
  @Input() modulo = '';

  @Output() clickPbaDropdown = new EventEmitter<DropDownSelected>();
  @Output() filterEvent = new EventEmitter<boolean>(); //false = down true = up

  list = new Array<DropdownItemInterface>();
  inputSearch = '';
  searchText?: string;
  searchBox = true;
  showButtonClearFilters = false;

  @ViewChild('search', { static: false }) private searchTextRef?: ElementRef;
  @ViewChild('dropdownRef', { static: false }) private dropdownRef!: ElementRef;
  @ViewChildren('dropdownItemRef', { read: ElementRef })
  private dropdownItemRef!: QueryList<ElementRef>;
  private focusIndex = 0;
  private focusDownIndex = 1;
  private cellSize = 45;
  private cellGroupSize = this.cellSize * 4;
  private searchInput$ = new Subject<string>();
  private clearfilters$ = new Subscription();

  constructor(private readonly translate: TranslateService, private store: Store) {
    this.searchInput$.pipe(debounceTime(1500)).subscribe(lastKeyupParams => this.executeHeadSearch(lastKeyupParams));
  }

  ngOnInit() {
    this.initDataDropDown();
    this.initFocus();

    this.clearfilters$ = this.store
      .select((state: any) => state.shared.table[this.modulo])
      .subscribe(modulo => {
        if (modulo && modulo.length <= 0) {
          this.clearfilters();
        }
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.initDataDropDown();
    this.initFocus();
    this.hadlerSelectedItem(changes['selectedItem']);
  }

  ngOnDestroy() {
    if (this.clearfilters$) this.clearfilters$.unsubscribe();
  }
  hadlerSelectedItem(changesSelectedItem: any) {
    if (
      changesSelectedItem.currentValue.searchText &&
      changesSelectedItem.currentValue.searchText != '' &&
      changesSelectedItem.previousValue &&
      changesSelectedItem.previousValue.searchText == undefined
    ) {
      this.executeHeadSearch(changesSelectedItem.currentValue.searchText);
    }

    if (
      changesSelectedItem.currentValue.searchText &&
      changesSelectedItem.currentValue.searchText != '' &&
      changesSelectedItem.currentValue.selected
    ) {
      setTimeout(() => {
        this.showButtonClearFilters = true;
        this.inputSearch = changesSelectedItem.currentValue.searchText;
      }, 0);
      this.filterEvent.emit(true);
    }
  }

  onClickDropDown(itemSelected: DropdownItemInterface, event?: any, index?: number) {
    this.selected = index;
    itemSelected.selected = true;
    this.clickPbaDropdown.emit({
      index: this.selected || 0,
      item: itemSelected as DropdownItem,
      selected: !(index === 0),
      searchText: this.inputSearch
    });
  }

  onChangeTextSearch() {
    this.initFocus();
    this.dropdownRef.nativeElement.scrollTop = 0;
  }

  onClickButtonDropDown() {
    this.searchTextRef?.nativeElement.focus();

    if (this.keepSearchTextClick) {
      this.onClickButtonDropDownWithKeepSearchText();
    } else {
      this.onClickButtonDropDownNotKeepSearchText();
    }
  }

  onKeyUpSelected(event: any) {
    event.preventDefault();
    // Simulamos click debido a que bootstrap al hacer filtrado en el input y no se entera del nuevo dom.
    const elements: Array<ElementRef> = this.dropdownItemRef['_results'].filter(
      (ref: any) => ref.nativeElement.style.display !== 'none'
    );
    elements[this.focusIndex].nativeElement.click();
  }

  onKeyDownArrowDown() {
    if (this.focusIndex < this.getListWithNodeShow().length - 1) {
      this.changeKeyDownArrowDownFocus();
      this.changeKeyDownArrowDownScroll();
    }
  }

  onKeyDownArrowUp() {
    if (this.focusIndex !== 0) {
      this.changeKeyDownArrowUpFocus();
      this.changeKeyDownArrowUpScroll();
    }
  }

  onKeyHeadSearch(value: string) {
    this.searchInput$.next(value);
  }

  trackById = (i: number, e: any) => e.id;

  private initDataDropDown() {
    if (this.selected === undefined) {
      this.selected = 0;
    }

    if (this.options) {
      this.list = _.cloneDeep(this.options.slice());
      this.list.forEach(node => (node.show = true));

      if (this.list[this.selected] === undefined || this.list[this.selected] === null) {
        this.selected = 0;
      }
    }
  }

  private initFocus() {
    if (this.list && this.getListWithNodeShow().length) {
      this.focusIndex = 0;
      this.focusDownIndex = 1;
      this.list.forEach(op => (op.focus = false));
      this.getListWithNodeShow()[this.focusIndex].focus = true;
    }
  }

  private onClickButtonDropDownWithKeepSearchText() {
    setTimeout(() => {
      if (this.searchTextRef) {
        this.searchTextRef.nativeElement.focus();
      }
      const elements: ElementRef[] = this.dropdownItemRef['_results'].filter(
        (ref: any) => ref.nativeElement.style.display !== 'none'
      );
      let index = elements.findIndex(element => +element.nativeElement.id === this.list[this.selected || 0].id);

      if (index === -1) {
        index = 0;
      }
      this.focusIndex = index;
      this.list.forEach(op => (op.focus = false));
      this.getListWithNodeShow()[this.focusIndex].focus = true;
      this.focusDownIndex = 1;
      if (this.focusIndex > 4) {
        this.focusDownIndex = this.focusIndex - 3;
      }
      this.changeKeyDownArrowDownScroll();
    }, 0);
  }

  private onClickButtonDropDownNotKeepSearchText() {
    setTimeout(() => {
      if (this.searchTextRef) {
        this.searchTextRef.nativeElement.focus();
      }
      if (this.searchText) this.searchText = '';
      this.initDataDropDown();
      this.list.forEach(node => {
        node.focus = false;
        node.show = true;
      });
      this.dropdownItemRef['_results'].forEach((ref: any) => (ref.nativeElement.style.display = 'flex'));
      let index = this.dropdownItemRef['_results'].findIndex(
        (element: any) => +element.nativeElement.id === this.list[this.selected || 0].id
      );
      if (index === -1) {
        index = 0;
      }
      this.focusIndex = index;
      this.list[this.focusIndex].focus = true;
      this.focusDownIndex = 1;
      if (this.focusIndex > 4) {
        this.focusDownIndex = this.focusIndex - 3;
      }
      this.changeKeyDownArrowDownScroll();
    }, 0);
  }

  private changeKeyDownArrowDownFocus() {
    this.getListWithNodeShow()[this.focusIndex].focus = false;
    this.focusIndex++;
    this.getListWithNodeShow()[this.focusIndex].focus = true;
  }

  private changeKeyDownArrowDownScroll() {
    const topPos = this.getTopPosDropDownShow();
    const pos = this.focusDownIndex * this.cellSize + 4 * this.cellSize;
    if (topPos >= pos) {
      this.dropdownRef.nativeElement.scrollTop = topPos - this.cellGroupSize;
      this.focusDownIndex++;
    }
  }

  private changeKeyDownArrowUpFocus() {
    this.getListWithNodeShow()[this.focusIndex].focus = false;
    this.focusIndex--;
    this.getListWithNodeShow()[this.focusIndex].focus = true;
  }

  private changeKeyDownArrowUpScroll() {
    const topPos = this.getTopPosDropDownShow();
    if (this.focusDownIndex * this.cellSize > topPos) {
      this.dropdownRef.nativeElement.scrollTop = topPos - this.cellSize;
      this.focusDownIndex--;
    }
  }

  private getTopPosDropDownShow() {
    const elements = this.dropdownItemRef['_results'].filter((ref: any) => ref.nativeElement.style.display !== 'none');
    const topPos = elements[this.focusIndex] ? elements[this.focusIndex].nativeElement.offsetTop : 0;
    return topPos;
  }

  private getListWithNodeShow() {
    return this.list.filter(node => node.show === undefined || node.show);
  }

  private executeHeadSearch(lastKeyupParams: string) {
    if (lastKeyupParams) {
      this.inputSearch = lastKeyupParams;
    }
    const item: DropdownItem = this.list[this.selected || 0] as DropdownItem;
    this.clickPbaDropdown.emit({
      index: this.selected || 0,
      item: item,
      selected: item.selected,
      searchText: this.inputSearch
    });

    if (!this.showButtonClearFilters && this.inputSearch && this.inputSearch !== '') {
      this.showButtonClearFilters = true;
      this.filterEvent.emit(true);
    }

    if (!this.showButtonClearFilters) {
      this.filterEvent.emit(false);
    }
  }

  clearfilters() {
    this.showButtonClearFilters = false;
    if (this.searchText) this.searchText = '';

    this.inputSearch = '';
  }

  clearfiltersEmit() {
    this.clearfilters();

    this.filterEvent.emit(false);

    this.clickPbaDropdown.emit({
      index: 0,
      item: new DropdownItem(0, ''),
      selected: false,
      searchText: ''
    });
  }
}
