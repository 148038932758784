import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[appDoubleClick]'
})
export class DoubleClickDirective {
  @Output() singleClick = new EventEmitter();
  @Output() doubleClick = new EventEmitter();
  timer: any;
  stopClick: any;

  @HostListener('click', ['$event']) onClick(e: any) {
    this.timer = 0;
    this.stopClick = false;
    const delay = 400;

    this.timer = setTimeout(() => {
      if (!this.stopClick) {
        this.singleClick.emit(e);
      }
    }, delay);
  }

  @HostListener('dblclick', ['$event']) onDblClick(e: any) {
    this.stopClick = true;

    clearTimeout(this.timer);
    this.doubleClick.emit(e);
  }
}
