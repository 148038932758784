import { NgModule } from '@angular/core';
import { PortalComponent } from '@shared/components/portal/portal.component';
import { PortalModule } from '@angular/cdk/portal';

@NgModule({
  imports: [PortalModule],
  exports: [PortalComponent],
  declarations: [PortalComponent]
})
export class CustomPortalModule {}
