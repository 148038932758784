import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from '@environments/environment';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { AuthenticationService } from '@services/authentication.service/authentication.service';
import { DomainService } from '@shared/services/domain/domain.service';
import { DomainsEnum } from '@shared/models/common/domains.enum';
const jwtHelper = new JwtHelperService();

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private pollingRequests = ['overall', 'info-of-new'];

  constructor(private authenticationService: AuthenticationService, private router: Router) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // add auth header with jwt if user is logged in and request is to api url
    const currentUser = this.authenticationService.currentUser.value;
    const isLoggedIn = currentUser && currentUser.token;
    const isBackendUrl = request.url.startsWith(environment.backendUrl);
    // const expirationDate = jwtHelper.getTokenExpirationDate(currentUser.token);

    if (!request.url.startsWith(environment.backendUrl + '/azure') && !request.url.includes('/users/info')) {
      if (currentUser && currentUser.token && !jwtHelper.isTokenExpired(currentUser.token)) {
        const expirationTime = moment(jwtHelper.getTokenExpirationDate(currentUser.token));
        const diffExpDate = expirationTime.diff(moment.now(), 'millisecond');
        if (diffExpDate <= 0) {
          this.authenticationService.logout();
        }
        const refreshTokenPeriod =
          this.authenticationService.refreshTokenPeriod ||
          moment(expirationTime)
            .subtract(5 * 60000)
            .toDate()
            .getTime();
        if (diffExpDate <= refreshTokenPeriod) {
          const isPollingRequests = this.pollingRequests.some(r => request.url.includes(r));
          if (!this.isRefreshing && !isPollingRequests) {
            this.isRefreshing = true;
            if (this.authenticationService.currentUser.value.token) {
              this.authenticationService.refreshToken().subscribe(() => {
                this.isRefreshing = false;
              });
            }
          }
        }
        if (isLoggedIn && isBackendUrl) {
          request = request.clone({
            setHeaders: {
              Authorization: `Bearer ${currentUser.token}`
            }
          });
        }
      } else {
        this.router.navigate(['/login']);
      }
    }
    return next.handle(request);
  }
}
