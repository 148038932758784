import { Directive, Output, EventEmitter, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appClickOutside]'
})
export class ScrollOutsideDirective {
  @Output() scrollOutside = new EventEmitter<any>();
  @Input() discardedClass: string | undefined;

  constructor(private elementRef: ElementRef) {}

  @HostListener('scroll', ['$event'])
  public onScroll(event: Event): void {
    const discardedByClass = this.getDiscardedByClass(event);
    if (!discardedByClass) {
      this.scrollOutside.emit(event);
    }
  }

  private getDiscardedByClass(event: Event) {
    let discardedByClass = false;
    if (this.discardedClass !== undefined) {
      const target = event.target as HTMLElement;
      if (target.classList.contains(this.discardedClass)) {
        discardedByClass = true;
      }
    }
    return discardedByClass;
  }
}
