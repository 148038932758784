import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { DropDownMenuSelected } from './interfaces/dropdown-menu-selected.interface';
import { DropdownMenuItem } from './interfaces/dropdown-menu-item.model';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-dropdown-menu',
  templateUrl: './dropdown-menu.component.html',
  styleUrls: ['./dropdown-menu.component.scss']
})
export class DropdownMenuComponent implements OnInit, OnDestroy {
  // @ViewChild('dropdownMenuRef', { read: ElementRef, static: false }) dropdownMenuRef!: ElementRef;
  @Input() options?: Array<DropdownMenuItem>;
  @Input() optional?: DropdownMenuItem;
  @Input() selected = 0;
  @Input() selectedById?: number;
  @Input() eventsDropDownMenu?: Observable<void>;
  @Input() disableOptions?: any;
  @Output() clickPbaDropdownMenu = new EventEmitter<DropDownMenuSelected>();

  list = new Array<DropdownMenuItem>();
  subscriptionValues = new Subscription();
  showMenu = false;
  firstLoad = true;
  data: any;

  ngOnInit() {
    if (this.eventsDropDownMenu) {
      this.subscriptionValues.add(this.eventsDropDownMenu.subscribe(data => this.initDataDropDownMenu(data)));
    }
  }

  ngOnDestroy() {
    this.subscriptionValues.unsubscribe();
  }

  private initDataDropDownMenu(data?: any) {
    if (data) {
      this.data = data;
    }
    this.showMenu = !this.showMenu;
    this.firstLoad = true;
    if (this.options) {
      this.list = this.options.slice();
      if (this.optional) {
        this.list.unshift(this.optional);
      }
      if (this.list[this.selected] === undefined || this.list[this.selected] === null) {
        this.selected = 0;
      }
      if (this.options.some(op => op.selected)) {
        const opSelected: Array<DropdownMenuItem> = this.options.filter(op => op.selected);
        opSelected.forEach(s => {
          s.selected = false;
          this.selected = this.list.findIndex(o => o.id === s.id);
        });
      }
      if (this.selectedById !== undefined) {
        this.selected = this.list.findIndex(o => o.id === this.selectedById);
      }
    }
  }

  onClickDropDownMenu(itemSelected: any, index: number) {
    if (
      (itemSelected && !itemSelected.enable && !this.disableOptions) ||
      (itemSelected && itemSelected.enable && this.disableOptions)
    ) {
      this.selected = index;
      itemSelected.selected = true;
      this.clickPbaDropdownMenu.emit({
        index: this.selected,
        item: itemSelected,
        data: this.data,
        selected: !(this.optional && index === 0)
      });
      this.showMenu = false;
    }
  }

  documentClickListener(): void {
    // if (this.dropdownMenuRef.nativeElement.contains(event.target) && !this.firstLoad) {
    //     this.showMenu = false;
    //   }
    if (!this.firstLoad) {
      this.showMenu = false;
    }
    this.firstLoad = false;
  }

  trackByIndex = (i: number) => i;
}
