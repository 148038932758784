import { ActionReducerMap } from '@ngrx/store';
import * as fromLogin from '../pages/login/store/login.reducer';
import * as fromHead from '../components/cabecera/store/head.reducer';
import * as fromTerminal from '@pages/terminals/store/terminal.reducer';
import * as fromShared from '@shared/store/shared.reducer';

// export interface AppState {
//   languages: HeadState;
//   login: LoginState;
// }

// export const appReducer: ActionReducerMap<AppState> = {
//   login: fromLogin.reducer,
//   languages: fromHead.reducer,
// };

export const appReducer: ActionReducerMap<any> = {
  login: fromLogin.reducer,
  languages: fromHead.reducer,
  terminal: fromTerminal.reducer,
  shared: fromShared.reducer
};
