import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { User } from '../../../shared/models/users/user.model';

export interface LoginState extends EntityState<User> {
  first: boolean;
}

function selectUserId(user: User) {
  return user.id as number;
}

export const loginAdapter: EntityAdapter<User> = createEntityAdapter<User>({
  selectId: selectUserId
});
