import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { BaseDto } from '@shared/models/data-base/base-dto.model';

export interface HeadState extends EntityState<BaseDto> {
  selectLanguageId: number;
  loggedIn: boolean;
}

function selectUserId(baseDto: BaseDto) {
  return baseDto.id as number;
}

export const adapter: EntityAdapter<BaseDto> = createEntityAdapter<BaseDto>({
  selectId: selectUserId
});
