import { Action, createReducer, on } from '@ngrx/store';
import { LoginState, loginAdapter } from './login.adapter';

import * as fromActions from './login.actions';

const initialState: LoginState = loginAdapter.getInitialState({
  first: true
});

const loginReducer = createReducer(
  initialState,
  on(fromActions.loginStartSuccess, (state: LoginState, { user }) => {
    return {
      ...state,
      user: user
    };
  })
);

export function reducer(state: LoginState | undefined, action: Action) {
  return loginReducer(state, action);
}
