<div class="m-4" appClickOutside (clickOutside)="onClickOutside($event)">
  <div class="d-flex justify-content-center border-none">
    <i class="icon-danger-xxxl"></i>
  </div>
  <div class="modal-body mt-3 d-flex justify-content-center" *ngIf="list">
    {{ list }}
  </div>

  <div class="modal-body text-center p-0" *ngIf="showConfirm">
    <p class="m-3">{{ confirmText | translate }}</p>
    <div class="d-flex justify-content-around">
      <button type="button" class="btn btn-primary pba-btn-large m-0" (click)="confirm()">
        {{ 'USERS.SESSION.YES' | translate }}
      </button>
      <button type="button" class="btn btn-outline pba-btn-large m-0" (click)="decline()">
        {{ 'COMMONS.DECLINE' | translate }}
      </button>
    </div>
  </div>
</div>
