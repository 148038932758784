import { Subject } from 'rxjs';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { ConfigTableService } from '@services/config-table/config-table.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import * as _ from 'lodash';
import { ModalConfirmComponent } from '@shared/components/modal/modal-confirm/modal-confirm.component';
import { ConfigModules } from '@shared/models/common/config-modules.enum';
import { ConfigColumns } from '../../../models/common/config-columns.enum';

@Component({
  templateUrl: './modal-column.component.html',
  styleUrls: ['./modal-column.component.scss']
})
export class ModalColumnComponent implements OnInit, OnDestroy {
  @Input() module = '';
  @Input() userColumn = '';
  @Input() isDomainAxess : boolean = false;
  columnsByUser: any;
  filterColumnList: any = [];
  emptySelection = false;
  public onCloseSubject!: Subject<boolean>;
  initialColumns: any = [];

  constructor(
    public bsModalRef: BsModalRef,
    private modalConfirm: BsModalRef,
    private configTable: ConfigTableService,
    private readonly translate: TranslateService,
    private modalService: BsModalService,
    private toastr: ToastrService
  ) {}
  public ngOnInit(): void {
    this.prepareColumns();
    this.onCloseSubject = new Subject();
  }

  prepareColumns() {
    this.columnsByUser = _.cloneDeep(this.userColumn);
    this.columnsByUser = this.userColumn;
    this.configTable.getConfigByModule(this.module).subscribe(value => {
      if (value.status === 200) {
        if(this.module == ConfigModules.TERMINAL && this.isDomainAxess){
          value.data=  value.data.filter((objeto: any) => objeto.code !== ConfigColumns.TERMINAL_RESETQUOTA);
        }
        this.initialColumns = _.cloneDeep(value.data);
    
        this.prepareListColumns(value.data);
      }
    });
  }

  prepareListColumns(totalColumns: any) {
    totalColumns.forEach((columnTotal: any) => {
      //let column = this.columnsByUser.find((elementUser: any) => elementUser.code === columnTotal.code);
      if (this.columnsByUser.find((elementUser: any) => elementUser.code === columnTotal.code) == undefined) {
        this.filterColumnList.push(columnTotal);
      }
    });
  }

  public onConfirm(): void {
    if (!this.emptySelection) {
      const configTableDto = { configTableItemList: null };

      const listInsert: any = [];

      this.columnsByUser.forEach((element: any, index: number) => {
        const column = { name: element.name, code: element.code, orderShow: index + 1 };
        listInsert.push(column);
      });
      configTableDto.configTableItemList = listInsert;
      this.configTable.createConfig(this.module, configTableDto).subscribe(value => {
        if (value.status === 200) {
          this.translate.get('TOAST.MESSAGE.25').subscribe(() => {
            this.toastr.success(this.translate.instant('TOAST.MESSAGE.25'), '', { timeOut: 5000 });
          });
          this.onCloseSubject.next(this.columnsByUser);
          this.bsModalRef.hide();
        } else {
          //lanzar error
          this.translate.get('TOAST.MESSAGE.26').subscribe(() => {
            this.toastr.success(this.translate.instant('TOAST.MESSAGE.26'), '', { timeOut: 5000 });
          });
          this.bsModalRef.hide();
        }
      });
    }
  }

  public onCancel(): void {
    this.onCloseSubject.next(false);
    this.bsModalRef.hide();
  }

  ngOnDestroy() {
    this.onCloseSubject.next(false);
    this.bsModalRef.hide();
  }
  drop(eventCurr: any) {
    const event: CdkDragDrop<string[]> = eventCurr;
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
    }
    if (!this.columnsByUser || this.columnsByUser.length == 0) {
      this.emptySelection = true;
    } else {
      this.emptySelection = false;
    }
  }

  reset() {
    const initialState: any = {};
    initialState['textToShow'] = 'COMMONS.RESET_COLUMNS';
    this.modalConfirm = this.modalService.show(ModalConfirmComponent, {
      initialState
    });

    this.modalConfirm.content.onCloseSubject.subscribe((result: any) => {
      if (result) {
        this.configTable.reset(this.module).subscribe(value => {
          if (value.status === 200) {
            this.onCloseSubject.next(this.initialColumns);
            this.bsModalRef.hide();
            this.translate.get('TOAST.MESSAGE.27').subscribe(() => {
              this.toastr.success(this.translate.instant('TOAST.MESSAGE.27'), this.translate.instant('TOAST.CONFIG'), {
                timeOut: 5000
              });
            });
          }
        });
      }
    });
  }
  trackByIndex = (i: number) => i;
}
