import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FavoritesService } from '../../services/favorites.service';
import { Observable, Subscription } from 'rxjs';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ModalAddFavoriteComponent } from '../modal-add-favorite/modal-add-favorite.component';
import { ModalConfirmComponent } from '@shared/components/modal/modal-confirm/modal-confirm.component';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { ActionsEnum } from '../../interfaces/actions.enum';
import { ConfigModules } from '@shared/models/common/config-modules.enum';
import { DomainService } from '../../../../services/domain/domain.service';
import { DomainsEnum } from '../../../../models/common/domains.enum';


@Component({
  selector: 'app-favorites',
  templateUrl: './favorites.component.html',
  styleUrls: ['./favorites.component.scss']
})
export class FavoritesComponent implements OnInit, OnDestroy {
  @Input() hasFilter = false;
  @Input() tableName = '';
  @Input() searchParams$!: Observable<any>;

  @Output() querySelect = new EventEmitter<string>();

  public modalRef!: BsModalRef;
  public showFavoriteList = false;
  public favoriteList: any[] = [];
  private subscription = new Subscription();
  private query = '';
  domainAxess = false;
  domainNms = true;


  constructor(
    private readonly translate: TranslateService,
    private favoritesService: FavoritesService,
    private modalService: BsModalService,
    private toastr: ToastrService
  ) {
    this.translate.use(this.translate.store.currentLang);
    this.translate.store.onLangChange.subscribe(lang => this.translate.use(lang.lang));
  }

  ngOnInit() {
    this.checkDomain();
    this.getFavorites();

    this.subscription = this.searchParams$.subscribe(query => (this.query = query));
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  private getFavorites() {
    if (this.tableName === undefined || this.tableName === '' || this.tableName === ConfigModules.ALERT) return;

    this.favoritesService.getFavorites(this.tableName).subscribe((response: any) => {
      if (response && response.data) {
        this.favoriteList = response.data;
        this.showFavoriteList = this.favoriteList.length > 0;
      }
    });
  }

  onSelectFavorite(item: any) {
    this.querySelect.emit(item.query);
  }

  onNewFavorite() {
    if (!this.hasFilter) return;

    this.modalRef = this.modalService.show(ModalAddFavoriteComponent, {
      class: 'modal-md'
    });

    this.modalRef.content.onCloseSubject$.subscribe((result: any) => {
      if (result) {
        const dto = {
          name: result.favoriteName,
          query: JSON.stringify(this.query)
        };

        this.favoritesService.newFavorite(this.tableName, dto).subscribe((response: any) => {
          this.getFavorites();
          this.responseStatus(response, ActionsEnum.ADD);
        });
      }
    });
  }

  onDeleteFavorite(item: any) {
    this.modalRef = this.modalService.show(ModalConfirmComponent);
    this.modalRef.content.onCloseSubject.subscribe((result: any) => {
      if (result) {
        this.favoritesService.deleteFavorite(item.id).subscribe((response: any) => {
          this.getFavorites();
          this.responseStatus(response, ActionsEnum.DELETE);
        });
      }
    });
  }

  private responseStatus(response: any, actions: ActionsEnum) {
    if (response.status === 200) {
      const title =
        actions === ActionsEnum.ADD
          ? this.translate.instant('COMPONENT.TABLE.FAVORITE.ADD_FILTER')
          : this.translate.instant('COMPONENT.TABLE.FAVORITE.DELETE_FILTER');
      this.toastr.success(this.translate.instant('TOAST.MESSAGE.OK'), title, { timeOut: 5000 });
    }
  }


  private checkDomain() {
    const domain = DomainService.getDomain();

    if(domain == DomainsEnum.AXESS){
      this.domainAxess = true;
      this.domainNms = false;
    }
  }
  trackByIndex = (i: number) => i;
}
