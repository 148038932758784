// import * as am4core from '@amcharts/amcharts4/core';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { Store } from '@ngrx/store';
import * as HeadActions from './components/cabecera/store/head.actions';
import { languageUtil } from '@shared/utils/languages.util';
import { RoleUserClient } from '@shared/classes/role-user-client';
import { AuthenticationService } from '@services/authentication.service/authentication.service';
import { DomainService } from './shared/services/domain/domain.service';
import { DomainsEnum } from './shared/models/common/domains.enum';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent extends RoleUserClient implements OnInit {
  domainAxess = false;
  domainNms = true;

  constructor(private translate: TranslateService, authenticationService: AuthenticationService, private store: Store,private titleService: Title) {
    super(authenticationService);
    translate.addLangs(languageUtil.map(language => language.name));
    translate.use('es');
  }

  ngOnInit() {
    
    this.checkDomain();
    const currentUser = this.authenticationService.currentUser.value;
    if (currentUser.language) {
      this.store.dispatch(HeadActions.selectLanguage({ id: currentUser.language.id }));
      this.translate.use(currentUser.language.code);
    }
  }

  changeFavicon(newIconUrl: string) {
    const link: HTMLLinkElement = document.querySelector('#favicon') || this.createFaviconLink();
    link.href = newIconUrl;
  }
  
  createFaviconLink(): HTMLLinkElement {
    const link: HTMLLinkElement = document.createElement('link');
    link.id = 'favicon';
    link.rel = 'icon';
    document.head.appendChild(link);
    return link;
  }

  private checkDomain() {
    const domain = DomainService.getDomain();

    if (domain == DomainsEnum.AXESS) {
      this.changeFavicon('/assets/icons/Axess_Logo_B.png');
      this.titleService.setTitle('Axess');
      this.domainAxess = true;
      this.domainNms = false;
    }
  
    document.body.classList.toggle('axess', this.domainAxess);
    document.body.classList.toggle('nms', this.domainNms);
  }
}
