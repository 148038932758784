import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SharedSearchService {
  constructor(private http: HttpClient) {}

  getSharedSearch(codigo: string) {
    return this.http.get<any>(environment.backendUrl + `/config-table-view/search-url/${codigo}`, {});
  }

  newSharedSearch(configTableModule: string, body: any): any {
    return this.http.post<any>(environment.backendUrl + `/config-table-view/search-url/${configTableModule}`, body);
  }
}
