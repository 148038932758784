import { ActionReducer, combineReducers } from '@ngrx/store';
import * as fromTerminalWifi from '@pages/terminals/components/terminal-wifi/store/terminal-wifi.reducer';

const terminalState = {
  wifi: fromTerminalWifi.reducer
};

const terminalReducer: ActionReducer<any> = combineReducers(terminalState);

export function reducer(state: any, action: any) {
  return terminalReducer(state, action);
}
