<div class="axess">
  <div class="fondo">
    <div class="contenido">
      <span class="logo">
        <img class="imagen-logo" src="../../../../../assets/logos/LogoBlancoFranjaAzul.png" alt="My Happy SVG" />
      </span>
      <span class="logo2">
        <img class="imagen-logo2" src="../../../../../assets/logos/MONITOREO.svg" alt="My Happy SVG" />
      </span>
    </div>
  </div>
</div>
