import { createAction, props } from '@ngrx/store';

export enum TableActionTypes {
  tableFiltersAdd = '[TABLE] Filters ADD',
  tableFiltersDelete = '[TABLE] Filters DELETE',
  tableFiltersReset = '[TABLE] Filters RESET',
  tableFiltersUpdate = '[TABLE] Filters UPDATE',
  tableClearFilters = 'TABLE CLEAR FILTERS',
  tableActiveFiltersUP = 'TABLE Active Filters UP',
  tableActiveFiltersDOWN = 'TABLE Active Filters DONW'
}

export const tableFiltersAdd = createAction(
  TableActionTypes.tableFiltersAdd,
  props<{ module: string; filterName: string }>()
);

export const tableFiltersDelete = createAction(
  TableActionTypes.tableFiltersDelete,
  props<{ module: string; filterName: string }>()
);

export const tableFiltersReset = createAction(TableActionTypes.tableFiltersReset, props<{ module: string }>());

export const tableClearFilters = createAction(TableActionTypes.tableClearFilters);

export const tableActiveFiltersUP = createAction(TableActionTypes.tableActiveFiltersUP);

export const tableActiveFiltersDOWN = createAction(TableActionTypes.tableActiveFiltersDOWN);
