import { createReducer, on } from '@ngrx/store';
import { tableFiltersAdd, tableFiltersDelete, tableFiltersReset } from './table.actions';

const initialState = {};

function onFiltersAdd(state: any, action: any) {
  const filtros = [...(state[action.module] || []), action.filterName];
  return { ...state, [action.module]: [...new Set(filtros)] };
}

function onFiltersDelete(state: any, action: any) {
  const filtros = [...state[action.module].filter((f: any) => f !== action.filterName)];
  return { ...state, [action.module]: filtros };
}

function onFiltersReset(state: any, action: any) {
  return { ...state, [action.module]: [] };
}

const tableReducer = createReducer(
  initialState,
  on(tableFiltersAdd, onFiltersAdd),
  on(tableFiltersDelete, onFiltersDelete),
  on(tableFiltersReset, onFiltersReset)
);

export function reducer(state: any, action: any) {
  return tableReducer(state, action);
}
