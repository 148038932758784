import { Directive, Output, EventEmitter, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appClickOutside]'
})
export class ClickOutsideDirective {
  @Output() clickOutside = new EventEmitter<any>();
  @Input() discardedClass: string | undefined;

  constructor(private elementRef: ElementRef) {}

  @HostListener('document:click', ['$event'])
  public onClick(event: Event) {
    const discardedByClass = this.getDiscardedByClass(event);
    const clickedInside = this.elementRef.nativeElement.contains(event.target);
    if (!clickedInside && !discardedByClass) {
      this.clickOutside.emit(event);
    }
  }

  private getDiscardedByClass(event: Event) {
    let discardedByClass = false;
    if (this.discardedClass !== undefined) {
      const target = event.target as HTMLElement;
      if (target.classList.contains(this.discardedClass)) {
        discardedByClass = true;
      }
    }
    return discardedByClass;
  }
}
