import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'filterjson'
})
export class FilterJsonPipe implements PipeTransform {
  transform(items: any[], filter: string, value: any): any {
    if (items && items.length > 0) {
      return items.find(item => item[filter] === value);
    }
  }
}
