import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { DropdownItemInterface } from '@shared/components/dropdown/interfaces/dropdown-item.interface';
import { DropDownMultipleSelected } from '@shared/components/dropdown/interfaces/dropdown-multiple-selected.interface';
import { Subscription } from 'rxjs';

import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-table-filter-select-multi',
  templateUrl: './table-filter-select-multi.component.html',
  styleUrls: ['./table-filter-select-multi.component.scss']
})
export class TableFilterSelectMultiComponent implements OnInit, OnChanges, OnDestroy {
  @Input() placeholder?: string;
  @Input() options?: Array<DropdownItemInterface>;
  @Input() optional?: DropdownItemInterface;
  @Input() uniqueSelection = false;
  @Input() selected?: number = 0;
  @Input() selectedById?: number;
  @Input() infinite = false;
  @Input() search = true;
  @Input() icon?: string;
  @Input() inputSearch = '';
  @Input() optionsFormMultiple: any[] = [];
  @Input() modulo = '';

  @Output() clickPbaDropdownMultiple = new EventEmitter<DropDownMultipleSelected>();
  @Output() infiniteScroll = new EventEmitter<boolean>();
  @Output() filterEvent = new EventEmitter<boolean>();

  disabled = false;
  list = new Array<DropdownItemInterface>();
  listStr = '';
  searchText?: string;
  searchBox = true;
  showButtonClearFilters = false;

  @ViewChild('search', { static: false }) private searchTextRef?: ElementRef;
  @ViewChild('dropdownRef', { static: false }) private dropdownRef!: ElementRef;
  @ViewChildren('dropdownItemRef', { read: ElementRef }) private dropdownItemRef!: QueryList<ElementRef>;

  private focusIndex = 0;
  private focusDownIndex = 1;
  private cellSize = 45;
  private cellGroupSize = this.cellSize * 4;
  private activeInputSearch = false;
  private clearfilters$ = new Subscription();

  constructor(private readonly translate: TranslateService, private store: Store, private route: ActivatedRoute) {}

  ngOnInit() {
    if (!this.search) {
      this.searchBox = false;
    }
    this.initDataDropDown();
    this.initFocus();

    this.clearfilters$ = this.store
      .select((state: any) => state.shared.table[this.modulo])
      .subscribe(modulo => {
        if (modulo && modulo.length <= 0) {
          this.clearfilters();
        }
      });

    this.route.queryParamMap.subscribe((params: any) => {
      setTimeout(() => {
        const clientsIds = params.get('clientIds');
        const slotIds = params.get('slotIds');

        if ((clientsIds || slotIds) && this.showButtonClearFilters) {
          this.disabled = true;
        }
      }, 1500);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    // console.log('ngOnChanges',changes)
    if (
      !this.showButtonClearFilters &&
      changes['options'] &&
      changes['options'].currentValue &&
      changes['options'].currentValue.some((op: any) => op.selected)
    ) {
      // this.store.dispatch(fromActions.tableActiveFiltersUP());
      this.filterEvent.emit(true);
    }

    this.initDataDropDown();
    this.initFocus();

    if (
      changes['optionsFormMultiple'] &&
      changes['optionsFormMultiple'].currentValue &&
      changes['optionsFormMultiple'].currentValue.length > 0 &&
      changes['optionsFormMultiple'].previousValue &&
      changes['optionsFormMultiple'].previousValue.length == 0 &&
      this.showButtonClearFilters
    ) {
      // this.store.dispatch(fromActions.tableActiveFiltersUP());
      this.filterEvent.emit(true);
    }
  }

  ngOnDestroy() {
    if (this.clearfilters$) this.clearfilters$.unsubscribe();
  }

  onClickDropDown(itemSelected: DropdownItemInterface, event?: any) {
    const numero = this.list.filter(s => s.selected === true).length;

    this.onClickCheckMultiple(itemSelected, event);

    this.showButtonClearFilters = this.list.some(s => s.selected === true);

    if (numero == 0 && this.showButtonClearFilters) {
      this.filterEvent.emit(true);
      // this.store.dispatch(fromActions.tableActiveFiltersUP());
    }

    if (!this.showButtonClearFilters) {
      this.filterEvent.emit(false);
      // this.store.dispatch(fromActions.tableActiveFiltersDOWN());
    }
  }

  onChangeTextSearch(searchText: string) {
    this.filterStatusNode(searchText);
    this.initFocus();
    this.dropdownRef.nativeElement.scrollTop = 0;
    // this.checkShowOptionalMultiple();
  }

  onClickButtonDropDown() {
    this.searchTextRef?.nativeElement.focus();
  }

  onKeyUpSelected(event: any) {
    event.preventDefault();
    // Simulamos click debido a que bootstrap al hacer filtrado en el input y no se entera del nuevo dom.
    const elements: Array<ElementRef> = this.dropdownItemRef['_results'].filter(
      (ref: any) => ref.nativeElement.style.display !== 'none'
    );
    elements[this.focusIndex].nativeElement.click();
  }

  onKeyDownArrowDown() {
    if (this.focusIndex < this.getListWithNodeShow().length - 1) {
      this.changeKeyDownArrowDownFocus();
      this.changeKeyDownArrowDownScroll();
    }
  }

  onKeyDownArrowUp() {
    if (this.focusIndex !== 0) {
      this.changeKeyDownArrowUpFocus();
      this.changeKeyDownArrowUpScroll();
    }
  }

  onScroll() {
    if (this.infinite) {
      const scrollTop = this.dropdownRef.nativeElement.scrollTop;
      const clientHeight = this.dropdownRef.nativeElement.clientHeight;
      const scrollHeight = this.dropdownRef.nativeElement.scrollHeight;
      if (scrollTop + clientHeight + 1 >= scrollHeight) {
        this.infiniteScroll.emit();
      }
    }
  }

  onBlurOptionsDropdown() {
    setTimeout(() => {
      if (!this.activeInputSearch) {
        this.clickPbaDropdownMultiple.emit({
          items: this.list.filter(node => node.selected && node.id !== this.optional?.id),
          searchText: this.inputSearch
        });
      }
    }, 0);
  }

  onBlurInputSearch() {
    this.activeInputSearch = false;
    this.dropdownRef.nativeElement.focus();
  }

  onFocusInputSearch() {
    this.activeInputSearch = true;
  }

  trackById = (i: number, e: any) => e.id;

  private initDataDropDown() {
    if (this.uniqueSelection) {
      this.optional = {
        id: -1,
        name: '',
        show: false,
        focus: false,
        selected: false
      };
    }
    if (this.selected === undefined) {
      this.selected = 0;
    }

    if (this.optionsFormMultiple && this.optionsFormMultiple.length == 0) {
      this.showButtonClearFilters = false;
      this.listStr = '';
      if (this.options && this.listStr != '') {
        this.list.map(node => {
          node.selected = false;
        });
      }
    }

    if (this.options) {
      this.list = this.options.slice();
      this.list.forEach(node => (node.show = true));
      if (this.optional) {
        this.list.unshift(this.optional);
      }
      if (this.list[this.selected] === undefined || this.list[this.selected] === null) {
        this.selected = 0;
      }

      const hasOptionSelected = this.options.some(op => op.selected);
      if (hasOptionSelected) {
        const opSelected: Array<DropdownItemInterface> = this.options.filter(op => op.selected);

        opSelected.forEach(s => {
          const index = this.list.findIndex(f => f.id == s.id);
          this.list[index].selected = true;
        });
      }

      this.listStr = this.list
        .filter(node => node.selected && node.id !== this.optional?.id)
        .map(c => this.translate.instant(c.name))
        .join(', ');

      this.showButtonClearFilters = this.listStr != '';
    }
  }

  private checkShowOptionalMultiple() {
    if (this.optional && this.searchText) {
      this.optional.show = !this.searchText;
    }
  }

  private filterStatusNode(searchText: string) {
    this.list.forEach(node => {
      if (node.id != -1) {
        node.show = !(
          !node.name || !this.translate.instant(node.name).toLowerCase().includes(searchText.toLowerCase())
        );
        if (node.selected) {
          node.show = true;
        }
      } else {
        node.show = true;
        if (node.selected) {
          node.show = true;
        }
      }
    });
  }

  private initFocus() {
    if (this.list && this.getListWithNodeShow().length) {
      this.focusIndex = 0;
      this.focusDownIndex = 1;
      this.list.forEach(op => (op.focus = false));
      this.getListWithNodeShow()[this.focusIndex].focus = true;
    }
  }

  private checkIsFocusActive() {
    if (!this.list.some(node => (node.show = true))) {
      this.focusIndex = 0;
      this.getListWithNodeShow()[this.focusIndex].focus = true;
    }
  }

  private changeKeyDownArrowDownFocus() {
    this.getListWithNodeShow()[this.focusIndex].focus = false;
    this.focusIndex++;
    this.getListWithNodeShow()[this.focusIndex].focus = true;
  }

  private changeKeyDownArrowDownScroll() {
    const topPos = this.getTopPosDropDownShow();
    const pos = this.focusDownIndex * this.cellSize + 4 * this.cellSize;
    if (topPos >= pos) {
      this.dropdownRef.nativeElement.scrollTop = topPos - this.cellGroupSize;
      this.focusDownIndex++;
    }
  }

  private changeKeyDownArrowUpFocus() {
    this.getListWithNodeShow()[this.focusIndex].focus = false;
    this.focusIndex--;
    this.getListWithNodeShow()[this.focusIndex].focus = true;
  }

  private changeKeyDownArrowUpScroll() {
    const topPos = this.getTopPosDropDownShow();
    if (this.focusDownIndex * this.cellSize > topPos) {
      this.dropdownRef.nativeElement.scrollTop = topPos - this.cellSize;
      this.focusDownIndex--;
    }
  }

  private getTopPosDropDownShow() {
    const elements = this.dropdownItemRef['_results'].filter((ref: any) => ref.nativeElement.style.display !== 'none');
    const topPos = elements[this.focusIndex] ? elements[this.focusIndex].nativeElement.offsetTop : 0;
    return topPos;
  }

  private getListWithNodeShow() {
    return this.list.filter(node => node.show === undefined || node.show);
  }

  private onClickCheckMultiple(item: DropdownItemInterface, event?: any) {
    item.selected = !item.selected;
    this.activeInputSearch = false;
    if (!item.selected) {
      this.filterStatusNode(this.searchText || '');
      this.checkIsFocusActive();
    }
    this.checkNodeSelectAllMultiple(item);
    //this.checkShowOptionalMultiple();
    event.stopPropagation();
    event.preventDefault();

    this.listStr = this.list
      .filter(node => node.selected && node.id !== this.optional?.id)
      .map(c => this.translate.instant(c.name))
      .join(', ');
  }

  private checkNodeSelectAllMultiple(item: DropdownItemInterface) {
    if (this.optional) {
      if (item.id === this.optional.id) {
        this.list.forEach(node => {
          if (node.show) {
            node.selected = item.selected;
          }

          //node.show = true;
          //if (this.searchText) this.searchText = '';
        });
      } else {
        this.optional.selected = this.list.every(node => node.selected || node.id === this.optional?.id);
      }
    }
  }

  clearfilters() {
    this.disabled = false;
    this.showButtonClearFilters = false;
    if (this.listStr) this.listStr = '';
    if (this.searchText) this.searchText = '';
    if (this.optionsFormMultiple) this.optionsFormMultiple = [];

    this.list.forEach(node => (node.selected = false));
  }

  clearfiltersEmit() {
    if (this.disabled) return;
    this.clearfilters();

    this.list.forEach(node => {
      node.selected = false;
      node.show = true;
    });
    this.clickPbaDropdownMultiple.emit({
      items: [],
      searchText: ''
    });

    this.filterEvent.emit(false);
    // this.store.dispatch(fromActions.tableActiveFiltersDOWN());
  }
}
