import { NgModule } from '@angular/core';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { CommonModule, DatePipe } from '@angular/common';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { DatePickerSearchComponent } from '@shared/components/date-picker-search/date-picker-search.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [DatePickerSearchComponent],
  imports: [
    BsDatepickerModule.forRoot(),
    TimepickerModule.forRoot(),
    CommonModule,
    AngularSvgIconModule,
    TranslateModule,
    FormsModule
  ],
  exports: [DatePickerSearchComponent],
  providers: [BsModalRef, DatePipe]
})
export class DatePickerSearchModule {}
