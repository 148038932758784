

<div  [ngClass]="{'axess': domainAxess, 'nms':domainNms}"><i
  *ngIf="hasFilter"
  class="btn btn-sm icon-share-blue-md button-clear-filters"
  (click)="onShareSearch()"
  title="Compartir búsqueda">
</i>

</div>
