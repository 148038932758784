import { Directive, ElementRef, OnInit, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appResize]'
})
export class ResizeDirective implements OnInit {
  initClientX = 0;
  initColumnWidth = 0;
  thHead: any;
  tds: any;
  columnWidthDefault = 200;
  th: any;
  tdDivs: any;

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngOnInit() {
    this.thHead = this.el.nativeElement.closest('th') as HTMLTableElement; //busco el th del head
    const compTable = this.el.nativeElement.closest('.comp-table') as HTMLTableElement;
    const tableBodyContainer = compTable.querySelector('.table-body-container') as HTMLTableElement;

    this.thHead.addEventListener('mousedown', (ev: MouseEvent) => {
      this.initClientX = ev.clientX;
      this.initColumnWidth = parseInt(getComputedStyle(this.thHead).width, 10);
      this.th = tableBodyContainer.querySelector(`th.p-0.column-head.col${this.thHead.cellIndex}`);
      this.tdDivs = tableBodyContainer.querySelectorAll(`td.ps-2.column-table.col${this.thHead.cellIndex}>div`);

      //inicializo escuchadores
      document.addEventListener('mousemove', this.mousemove);
      document.addEventListener('mouseup', this.mouseup);
    });
  }

  mousemove = (ev: MouseEvent) => {
    const columnWidth = this.initColumnWidth + ev.clientX - this.initClientX;

    if (columnWidth <= this.columnWidthDefault) return; // no se puede hacer resize por debajo del tamaño minimo de columna

    this.renderer.setStyle(this.th, 'min-width', '');
    this.tdDivs.forEach((div: any) => this.renderer.setStyle(div, 'width', ``));

    // table header
    this.renderer.setStyle(this.thHead, 'min-width', `${columnWidth}px`);
    this.renderer.setStyle(this.thHead, 'border-right', '1px solid #ced4da');

    const px1 = this.thHead.querySelector('.px-1');
    if (px1) {
      this.renderer.setStyle(px1, 'min-width', `${columnWidth}px`);
      this.renderer.setStyle(px1, 'width', `${columnWidth}px`);
    }

    // table body
    const compTable = this.el.nativeElement.closest('.comp-table') as HTMLTableElement;

    // table body head
    const tableBodyContainer = compTable.querySelector('.table-body-container') as HTMLTableElement;
    const divs = tableBodyContainer.querySelectorAll(`th.p-0.column-head.col${this.thHead.cellIndex}>div`);
    divs.forEach((div: Element) => {
      this.renderer.setStyle(div, 'width', `${columnWidth}px`);
      this.renderer.setStyle(div, 'min-width', `${columnWidth}px`);
    });

    // table body body
    this.tds = compTable.querySelectorAll(`td.ps-2.column-table.col${this.thHead.cellIndex}`);
    this.tds.forEach((td: HTMLTableElement) => {
      this.renderer.setStyle(td, 'min-width', `${columnWidth}px`);
      this.renderer.setStyle(td, 'border-right', '1px solid #ced4da');
    });
  };

  mouseup = () => {
    document.removeEventListener('mousemove', this.mousemove);
    document.removeEventListener('mouseup', this.mouseup);

    if (this.thHead) this.thHead.style.borderRight = '';

    if (this.tds) {
      this.tds.forEach((td: HTMLTableElement) => {
        this.renderer.setStyle(td, 'border-right', '');
      });
    }
  };
}
