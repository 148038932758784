import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ErrorOauth2Component } from '@pages/login/login-oauth2/error-oauth2/error-oauth2.component';
import { LoginOauth2Component } from '@pages/login/login-oauth2/login-oauth2.component';
import { LoginComponent } from '@pages/login/login.component';
// import { UrlPba } from '@components/menu/url.enum';

const routes: Routes = [
  { path: 'axess', component: LoginOauth2Component },
  { path: 'axess/login-axess', component: ErrorOauth2Component },
  { path: 'login', component: LoginComponent },
  {
    path: '',
    loadChildren: () => import('./components/home/home.module').then(m => m.HomeModule)
  },
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: '**', redirectTo: '/dashboard' } // poner PageNotFoundComponent { path: '**', component: <component-name> }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
