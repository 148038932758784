import { ActionReducer, combineReducers } from '@ngrx/store';
import * as fromTable from '@shared/components/table/store/table.reducer';

const sharedState = {
  table: fromTable.reducer
};

const sharedReducer: ActionReducer<any> = combineReducers(sharedState);

export function reducer(state: any, action: any) {
  return sharedReducer(state, action);
}
