import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Observable, Subject } from 'rxjs';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit, OnDestroy {
  @Output() datos: EventEmitter<any> = new EventEmitter<any>();
  @Output() cancel: EventEmitter<any> = new EventEmitter<any>();
  @Input() events?: Observable<void>;
  onClose: Subject<boolean>;
  textToShow: any = '¿Seguro que desea continuar?';

  constructor(public bsModalRef: BsModalRef) {
    this.onClose = new Subject();
  }

  ngOnInit() {
    if (this.events) {
      this.events.subscribe((data: any) => {
        if (data !== undefined && data !== null) {
          this.textToShow = data;
        }
      });
    }
  }

  emitir(dato: any) {
    this.datos.emit(dato);
  }

  cancelar(dato: any) {
    this.cancel.emit(dato);
  }

  ngOnDestroy() {
    this.onClose.next(false);
    this.bsModalRef.hide();
  }
}
