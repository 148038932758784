import { NgModule } from '@angular/core';
import { InputEditTableComponent } from '@shared/components/input-edit-table/input-edit-table.component';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [InputEditTableComponent],
  imports: [CommonModule, TranslateModule, FormsModule],
  exports: [InputEditTableComponent],
  providers: []
})
export class InputEditTableModule {}
