import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable, Subscription } from 'rxjs';
import { TableShareSearchModalComponent } from '../table-share-search-modal/table-share-search-modal.component';
import { DomainService } from '../../../../services/domain/domain.service';
import { DomainsEnum } from '../../../../models/common/domains.enum';

@Component({
  selector: 'app-table-share-search',
  templateUrl: './table-share-search.component.html',
  styleUrls: ['./table-share-search.component.scss']
})
export class TableShareSearchComponent implements OnInit, OnDestroy {
  @Input() hasFilter = false;
  @Input() tableName = '';
  @Input() searchParams$!: Observable<any>;

  public modalRef!: BsModalRef;
  private subscription = new Subscription();
  private query = '';

  domainAxess = false;
  domainNms = true;


  constructor(private readonly translate: TranslateService, private modalService: BsModalService) {}

  ngOnInit() {
    this.checkDomain();
    this.subscription = this.searchParams$.subscribe(query => (this.query = query));
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  onShareSearch() {
    if (!this.hasFilter) return;

    const initialState: any = {
      module: this.tableName,
      query: this.query
    };

    this.modalRef = this.modalService.show(TableShareSearchModalComponent, {
      initialState,
      class: 'modal-lg'
    });
  }

  private checkDomain() {
    const domain = DomainService.getDomain();

    if(domain == DomainsEnum.AXESS){
      this.domainAxess = true;
      this.domainNms = false;
    }
  }
}
