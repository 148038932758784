import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ItemsPerPageEnum } from './interfaces/items-per-page.enum';
import { DropdownItem } from '../dropdown/interfaces/dropdown-item.model';

@Component({
  selector: 'app-table-configuration-bar',
  templateUrl: './table-configuration-bar.component.html',
  styleUrls: ['./table-configuration-bar.component.scss']
})
export class TableConfigurationBarComponent {
  @Output() clickNumberItemsDisplay = new EventEmitter<number>();
  @Input() id = 10;

  numberDisplay = new Array<DropdownItem>();

  constructor() {
    this.numberDisplay = [
      new DropdownItem(
        ItemsPerPageEnum.TEN_ELEMENTS,
        'USERS.TABLE.CONFIG.ITEMS_PER_PAGE',
        ItemsPerPageEnum.TEN_ELEMENTS
      ),
      new DropdownItem(
        ItemsPerPageEnum.TWENTY_ELEMENTS,
        'USERS.TABLE.CONFIG.ITEMS_PER_PAGE',
        ItemsPerPageEnum.TWENTY_ELEMENTS
      ),
      new DropdownItem(
        ItemsPerPageEnum.FIFTY_ELEMENTS,
        'USERS.TABLE.CONFIG.ITEMS_PER_PAGE',
        ItemsPerPageEnum.FIFTY_ELEMENTS
      )
    ];
  }

  onClickDropDownNumberDisplay(data: any) {
    this.clickNumberItemsDisplay.emit(data.item.id);
  }
}
