import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DropdownComponent } from './dropdown.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { AngularSvgIconPreloaderModule } from '../../../../vendor/angular-svg-icon-preloader/angular-svg-icon-preloader.module';

@NgModule({
  declarations: [DropdownComponent],
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    AngularSvgIconModule,
    AngularSvgIconPreloaderModule.forRoot({
      configUrl: '../../../assets/icons/svg-icons.json'
    })
  ],
  exports: [DropdownComponent]
})
export class DropDownModule {
  static AngularSvgIconModule: any;
}
