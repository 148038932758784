import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap } from 'rxjs/operators';
import * as HeadActions from './head.actions';
import { DataLanguageService } from '../data-language.service';
import { BaseDto } from '@shared/models/data-base/base-dto.model';

@Injectable()
export class HeadEffects {
  constructor(private actions$: Actions, protected dataLanguageService: DataLanguageService) {}

  requestgetLanguages$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(HeadActions.HeadActionTypes.getLanguagesStartRequest),
      switchMap(() => {
        return this.dataLanguageService.getLanguages().pipe(
          map((languages: Array<BaseDto>) => {
            return HeadActions.setLanguages({ languages: languages });
          })
        );
      })
    );
  });

  requestUpdateLanguage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(HeadActions.HeadActionTypes.updateLanguageRequest),
      switchMap((action: { code: string; id: number }) =>
        this.dataLanguageService.updateLanguage(action.code).pipe(
          map(() => {
            return HeadActions.selectLanguage({ id: action.id });
          })
        )
      )
    );
  });
}
