import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import * as fromApp from './app.reducer';
import { HeadEffects } from '../components/cabecera/store/head.effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '@environments/environment';

let instrument = [StoreDevtoolsModule.instrument()];
if (environment.production) {
  instrument = [];
}

@NgModule({
  imports: [
    StoreModule.forRoot(fromApp.appReducer, {
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
        strictStateSerializability: true,
        strictActionSerializability: true
      }
    }),
    EffectsModule.forFeature([HeadEffects]),
    EffectsModule.forRoot(),
    ...instrument
  ],
  declarations: []
})
export class RootStoreModule {}
